import React from 'react';

interface Testimonial {
  quote: string;
  name: string;
  title: string;
}

const Testimonial: React.FC<Testimonial> = ({ quote, name, title }) => (
  <div className="bg-white rounded-lg shadow-md p-6 w-full max-w-[600px] mb-6">
    <p className="text-[#45484C] text-[14px] lg:text-[16px] mb-4 leading-[150%]">
      "{quote}"
    </p>
    <div className="font-semibold text-[#1D2E54] text-[18px] mb-1">{name}</div>
    <div className="text-[#45484C] text-[14px]">{title}</div>
  </div>
);

export const TestimonialSection: React.FC = () => {
  const testimonials: Testimonial[] = [
    {
      quote: "My week begins in DataScoop. It informs me of important changes to my business, and improves my time spent on dashboards. It proactively uncovers trends and problems that would otherwise catch us off guard. My team looks forward to DataScoop narrative every Monday morning.",
      name: "Hai Tran",
      title: "Head of Product and Engineering"
    },
    {
      quote: "DataScoop uses comprehensive data collection and analysis methodologies to effectively direct towards the root causes related to a business problem. The visualization towards isolating the problem to specific segments is well-structured and is powerful in drawing insights from metrics trends and doing targeted study. The tool also follows a thorough stakeholder involvement process and considers any user input relevant for analysis/insights. Overall, I would recommend DataScoop for business users tracking KPIs/business metrics trends on a regular basis.",
      name: "Debasmita Mukherjee",
      title: "Senior Manager, Engineering & Data Science"
    },
    {
      quote: "DataScoop diagnoses metric deviations comprehensively, going beyond traditional tools. It synthesizes external data (holidays, events, news) to contextualize metric shifts and presents complex insights as readable narratives. If analyzing a business metric is a 100-mile journey, this tool covers the first 50 miles effortlessly, allowing analysts to focus on deeper, strategic insights. Leveraging recent AI advancements, DataScoop represents the future of analytics.",
      name: "Aditi Das",
      title: "Senior Data Scientist"
    }
  ];

  return (
    <section id="testimonials" className="py-[36px] lg:py-[48px] bg-[#F3F2FF] mb-[48px]">
      <div className="container-custom">
        <h2 className="font-semibold text-center leading-[120%] text-[#1D2E54] text-[24px] lg:text-[32px] mb-3 lg:mb-4">
          What our customers have to say
        </h2>
        <div className="leading-[150%] text-center text-[14px] lg:text-[18px] text-[#45484C] mb-6 lg:mb-8">
          Real insights from business leaders and analysts using DataScoop
        </div>
        <div className="flex flex-col items-center justify-center">
          {testimonials.map((testimonial, index) => (
            <Testimonial key={index} {...testimonial} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialSection;