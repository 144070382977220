interface StepComponProps {
    src: string;
    alt: string;
    number: string;
    title: string;
    onClick?: (event: React.MouseEvent<Element, MouseEvent>) => void;
}

export const OnboardingStepComponent = ({ src, alt, number, title, onClick }: StepComponProps) => {
    return (
        <div className="flex items-center flex-col accent-block p-6 relative w-full">
            <div className="flex items-center font-semibold -left-[20px] -top-[20px] absolute bg-[#445082] border-2 border-[#7F8ACA] h-[48px] justify-center leading-[125%] rounded-full text-[#FFFFFF] text-[24px] w-[48px]">
                {number}
            </div>
            <img className="mb-6" src={src} alt={alt} />
            <div className="font-semibold mb-[62px] text-[#445082] text-[20px]">
                {title}
            </div>
            <button
                id="see-how-1"
                className="flex items-center font-semibold border border-[#CACBCB] duration-300 ease-in-out hover:bg-[#F3F2FF] hover:border-[#AAABAC] px-4 py-[10px] rounded-[10px] show-video-popup text-[#2C2E30] text-[14px]"
                type="button"
                onClick={onClick}
            >
                See How
                <svg
                    className="ml-2"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clip-path="url(#clip0_739_881)">
                        <path
                            d="M10.0001 18.3332C14.6025 18.3332 18.3334 14.6022 18.3334 9.99984C18.3334 5.39746 14.6025 1.6665 10.0001 1.6665C5.39771 1.6665 1.66675 5.39746 1.66675 9.99984C1.66675 14.6022 5.39771 18.3332 10.0001 18.3332Z"
                            stroke="#2C2E30"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M7.91675 7.47095C7.91675 7.07321 7.91675 6.87434 7.99987 6.76332C8.0723 6.66657 8.18318 6.60603 8.30373 6.59742C8.44207 6.58754 8.60935 6.69508 8.94392 6.91016L12.8777 9.43905C13.1681 9.62569 13.3132 9.719 13.3634 9.83766C13.4072 9.94134 13.4072 10.0583 13.3634 10.162C13.3132 10.2807 13.1681 10.374 12.8777 10.5606L8.94392 13.0895C8.60935 13.3046 8.44207 13.4121 8.30373 13.4023C8.18318 13.3936 8.0723 13.3331 7.99987 13.2364C7.91675 13.1253 7.91675 12.9265 7.91675 12.5287V7.47095Z"
                            stroke="#2C2E30"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_739_881">
                            <rect width="20" height="20" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </button>
        </div>
    );
};