import { useEffect, useState } from "react";
import { HomeSavedItem } from "./homeSavedItem";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { setReports } from "../../store/reportsSlice";

type ReportType = {
  report_id: number,
  report_name: string,
  is_dataset: boolean,
}

export const HomeSavedTab = () => {
  const reports = useSelector((store: RootState) => store.reports.reports)
  // @ts-ignore
  const subscribedReports = useSelector((store: RootState) => store.user?.user?.reports_subscribed || [])
  const [subscribedIds, setSubscribedIds] = useState<number[]>(subscribedReports.map((item: any) => item.report_id))
  const dispatch = useDispatch();
    // @ts-ignore
  const nonDatasetReports = reports.filter((report: ReportType) => !report.is_dataset);

  useEffect(() => {
    setSubscribedIds(subscribedReports.map((item: any) => item.report_id));
  }, [subscribedReports]);

  const handleRemoveReport = (reportId: number) => {
    console.log(`Remove report with ID ${reportId}`);
    dispatch(setReports(reports.filter((report: ReportType) => report.report_id !== reportId)));
  };

  const handleSubscriptionChange = (reportId: number, newSubscriptionState: boolean) => {
    if (newSubscriptionState) {
      setSubscribedIds(prev => [...prev, reportId]);
    } else {
      setSubscribedIds(prev => prev.filter(id => id !== reportId));
    }
    // You might want to dispatch an action here to update the global state
  };

  const renderReportsList = (title: string, reportsList: ReportType[]) => (
    <>
      <h2 className="font-semibold border-b border-b-[#E6E6E7] py-6 text-[#1D2E54] text-[20px] text-center">
        {title}
      </h2>
      <div className="px-6">
        {reportsList.map((report: ReportType) => (
          <HomeSavedItem
            key={report.report_id}
            name={report.report_name}
            subscribed={subscribedIds.includes(report.report_id)}
            // @ts-ignore
            report={report}
            onRemoveClick={() => handleRemoveReport(report.report_id)}
            onSubscriptionChange={handleSubscriptionChange}
          />
        ))}
      </div>
      <br/>
    </>
  );

  return (
    <div className="max-w-[864px] w-full accent-block">
      {renderReportsList("My Analyses", nonDatasetReports)}
      {/* {renderReportsList("Team's Analyses", nonDatasetReports)} */}
      {/* {renderReportsList("Organization's Analyses", nonDatasetReports)} */}
    </div>
  );
};