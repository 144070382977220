import { Input } from "../../../components/inputs/input";
import { ArrowRightIcon } from "../../../components/UI/svgIcons";

interface UserDataStepProps {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  nextStep: () => void;
}

export const EmailStep = ({ nextStep, email, setEmail }: UserDataStepProps) => {
  const handleNext = () => {
    console.log("Next button clicked");
    nextStep();
  };

  return (
    <>
      <div className="flex items-center relative justify-between mb-[40px]">
        {/* <!-- Row decor --> */}
        <div className="w-full absolute bg-[#E6E6E7] h-[1px] top-[15px] z-0"></div>
        {/* <!-- Items --> */}
        <div className="flex items-center flex-col bg-[#ffffff] overflow-hidden relative z-[1] px-[25px]">
          <div className="flex items-center justify-center font-medium h-[32px] leading-[140%] mb-2 rounded-full text-[14px] w-[32px] bg-[#445082] text-[#F4F6F8]">
            1
          </div>
          <div className="font-medium text-[#1D2E54] text-[12px]">
            Enter Details
          </div>
        </div>
        <div className="flex items-center flex-col bg-[#ffffff] overflow-hidden relative z-[1]">
          <div className="flex items-center justify-center font-medium h-[32px] leading-[140%] mb-2 rounded-full text-[14px] w-[32px] bg-[#F3F2FF] text-[#1D2E54]">
            2
          </div>
          <div className="font-medium text-[#1D2E54] text-[12px]">
            Verify Code
          </div>
        </div>
        <div className="flex items-center flex-col bg-[#ffffff] overflow-hidden relative z-[1] px-[25px]">
          <div className="flex items-center justify-center font-medium h-[32px] leading-[140%] mb-2 rounded-full text-[14px] w-[32px] bg-[#F3F2FF] text-[#1D2E54]">
            3
          </div>
          <div className="font-medium text-[#1D2E54] text-[12px]">
            Change Password
          </div>
        </div>
      </div>
      <div className="mb-6">
        <div className="relative mb-6">
          <Input
            label="Email"
            htmlFor="email"
            name="email"
            id="email"
            type="email"
            placeholder="Enter your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <button
          className="w-full accent-btn p-3"
          type="button"
          onClick={handleNext}
        >
          Next
          <ArrowRightIcon iconClass="ml-3" />
        </button>
      </div>
    </>
  );
};
