import { useContext, useState } from "react";
import { UserDataStep } from "./userDataStep";
import { OnboardingSecureStep } from "./onboardingSecureStep";
import {
  CognitoUserAttribute,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import { awsConfig } from "../../../services/awsConfig";
import AWS from "aws-sdk";
import { BackStepButton } from "../../../components/auth-components/backStepBtn";
import { AuthContext } from "../../../utils/authContext";

export enum StepsRegEnum {
  userData = "userData",
  onboardingSecure = "onboardingSecure",
  onboardingSteps = "onboardingSteps",
}

export const RegisterPage = () => {
  const { handleLogin, fetchSession, signUp } = useContext(AuthContext);
  const [currentStep, setCurrentStep] = useState(StepsRegEnum.userData);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [secureCode, setSecureCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const userPool = new CognitoUserPool({
    UserPoolId: awsConfig.userPoolId,
    ClientId: awsConfig.clientId,
  });

  AWS.config.update({
    region: awsConfig.region,
    accessKeyId: awsConfig.accessKeyId,
    secretAccessKey: awsConfig.secretAccessKey,
  });

  const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider();

  const checkIfUserExists = async (email: string) => {
    const params = {
      UserPoolId: awsConfig.userPoolId,
      Filter: `email="${email}"`,
    };

    try {
      const data = await cognitoIdentityServiceProvider.listUsers(params).promise();
      return data.Users && data.Users.length > 0;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  // const checkIfUserExistsAndConfirmed = async (email: string) => {
  //   const params = {
  //     UserPoolId: awsConfig.userPoolId,
  //     Filter: `email="${email}"`,
  //   };
  //   try {
  //     const data = await cognitoIdentityServiceProvider.listUsers(params).promise();
  //     const users = data.Users || [];
  //     for (const user of users) {
  //       const confirmed = user.Attributes?.find(attr => attr.Name === 'custom:confirmed')?.Value === 'true';
  //       const verified = user.Attributes?.find(attr => attr.Name === 'custom:verified')?.Value === 'true';
  //       if (confirmed && verified) {
  //         return true; // User exists and is confirmed/verified
  //       }
  //     }
  //     return false; // User does not exist or is not confirmed/verified
  //   } catch (err) {
  //     console.error("Error checking if user exists and confirmed:", err);
  //     throw err;
  //   }
  // };


  const handleNext = async () => {
    try {
      const userExists = await checkIfUserExists(email);
      if (userExists) {
        setErrorMessage("The email already exists in the database, please sign in");
        return;
      }
      signUp(email, password, () => setCurrentStep(StepsRegEnum.onboardingSecure));
    } catch (err) {
      console.error("Error checking if user exists:", err);
      setErrorMessage("Error checking if user exists.");
    }
  };


  const handlePrevStep = () => {
    if (currentStep === StepsRegEnum.onboardingSecure) {
      setCurrentStep(StepsRegEnum.userData);
    }
  };

  return (
    <>
      {currentStep === StepsRegEnum.userData && (
        <UserDataStep
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          nextStep={handleNext}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )}
      {currentStep === StepsRegEnum.onboardingSecure && (
        <OnboardingSecureStep
          firstName={firstName}
          lastName={lastName}
          email={email}
          signUp={() => signUp(email, password, () => setCurrentStep(StepsRegEnum.onboardingSecure))}
          secureCode={secureCode}
          setSecureCode={setSecureCode}
          nextStep={() => setCurrentStep(StepsRegEnum.onboardingSteps)}
          userPool={userPool}
          password={password}
          backButton={<BackStepButton prevStep={handlePrevStep} />}
        />
      )}
      {/* {currentStep === StepsRegEnum.onboardingSteps && <OnboardingStepsStep />} */}
    </>
  );
};
// function setErrorMessage(arg0: string) {
//   throw new Error("Function not implemented.");
// }

