interface CardProps {
    icon: JSX.Element;
    title: string;
    subContent?: string;
    content: JSX.Element | string | JSX.Element[];
    showSubContent?: boolean;
}

export const CardOverview = ({ icon, title, subContent, content, showSubContent }: CardProps) => {
    return (
        <div className="border border-[#E6E6E7] p-6 shadow-md w-full max-w-[304px] min-h-[170px] rounded-[20px]">
            <div className="flex items-center justify-between gap-3 mb-6">
                <div className="flex items-center font-semibold leading-[130%] text-[#2C2E30] text-[20px]">
                    {icon}
                    {title}
                </div>
                {showSubContent && (
                    <div className="font-medium text-[14px] leading-[140%] text-[#45484C]">
                        {subContent}
                    </div>
                )}
                <div className="font-medium text-[14px] leading-[140%] text-[#45484C]">
                    {subContent}
                </div>
            </div>
            <div className="font-medium text-[16px] leading-[150%] text-[#35383B] whitespace-pre-line">
            {Array.isArray(content) ? content.join(', ') : content}
            </div>
        </div>
    )
}