import './SliderSection.css';
import AvatarRow from 'assets/images/avatar-row.png';
import AvatarRow2 from 'assets/images/avatar-row-2.png';
import AvatarRow3 from 'assets/images/avatar-row-3.png';
import AvatarRow4 from 'assets/images/avatar-row-4.png';

export const SliderSection = () => {
    return (
        <section className="mt-[48px]"  id="questions">
          <div className="">
            <div className="container-custom">
              <div className="text-center mb-[32px] lg:mb-10">
                <h2 className="font-semibold leading-[120%] text-[#1D2E54] text-[24px] lg:text-[32px] lg:mb-4 mb-3">Analysts are swamped</h2>
                <div className="leading-[150%] text-[14px] text-[#45484C] lg:text-[18px]">with requests to explain metric deviations</div>
              </div>
            </div>

            <div className="overflow-hidden white-shadow container-custom">
              <div className="flex gap-4 my-4 whitespace-nowrap left-blinking-text animate-slide-left">
                <div className="flex items-center justify-center bg-[#f7f8fa] gap-4 leading-[140%] lg:p-3 lg:text-[16px] min-w-[450px] p-[10px] rounded-[50px] text-[#1F2022] text-[14px]">
                  <img src={AvatarRow4} alt="Avatar." />
                  Why are email open rates declining?
                </div>
                <div className="flex items-center justify-center bg-[#f7f8fa] gap-4 leading-[140%] lg:p-3 lg:text-[16px] min-w-[450px] p-[10px] rounded-[50px] text-[#1F2022] text-[14px]">
                  <img src={AvatarRow} alt="Avatar." />
                  Why is time spent on site down today?
                </div>
                <div className="flex items-center justify-center bg-[#f7f8fa] gap-4 leading-[140%] lg:p-3 lg:text-[16px] min-w-[450px] p-[10px] rounded-[50px] text-[#1F2022] text-[14px]">
                  <img src={AvatarRow2} alt="Avatar." />
                  What explains the downward trend in revenue?
                </div>
                <div className="flex items-center justify-center bg-[#f7f8fa] gap-4 leading-[140%] lg:p-3 lg:text-[16px] min-w-[450px] p-[10px] rounded-[50px] text-[#1F2022] text-[14px]">
                  <img src={AvatarRow3} alt="Avatar." />
                  What caused this month's payment issue spike?
                </div>
                <div className="flex items-center justify-center bg-[#f7f8fa] gap-4 leading-[140%] lg:p-3 lg:text-[16px] min-w-[450px] p-[10px] rounded-[50px] text-[#1F2022] text-[14px]">
                  <img src={AvatarRow4} alt="Avatar." />
                  How did the new feature affect engagement?
                </div>
                <div className="flex items-center justify-center bg-[#f7f8fa] gap-4 leading-[140%] lg:p-3 lg:text-[16px] min-w-[450px] p-[10px] rounded-[50px] text-[#1F2022] text-[14px]">
                  <img src={AvatarRow} alt="Avatar." />
                  What's behind the dip in social media referrals?       
                </div>
                <div className="flex items-center justify-center bg-[#f7f8fa] gap-4 leading-[140%] lg:p-3 lg:text-[16px] min-w-[450px] p-[10px] rounded-[50px] text-[#1F2022] text-[14px]">
                  <img src={AvatarRow4} alt="Avatar." />
                  Why are email open rates declining?
                </div>
                <div className="flex items-center justify-center bg-[#f7f8fa] gap-4 leading-[140%] lg:p-3 lg:text-[16px] min-w-[450px] p-[10px] rounded-[50px] text-[#1F2022] text-[14px]">
                  <img src={AvatarRow} alt="Avatar." />
                  Why is time spent on site down today?
                </div>
                <div className="flex items-center justify-center bg-[#f7f8fa] gap-4 leading-[140%] lg:p-3 lg:text-[16px] min-w-[450px] p-[10px] rounded-[50px] text-[#1F2022] text-[14px]">
                  <img src={AvatarRow2} alt="Avatar." />
                  What explains the downward trend in revenue?
                </div>
                <div className="flex items-center justify-center bg-[#f7f8fa] gap-4 leading-[140%] lg:p-3 lg:text-[16px] min-w-[450px] p-[10px] rounded-[50px] text-[#1F2022] text-[14px]">
                  <img src={AvatarRow3} alt="Avatar." />
                  What caused this month's payment issue spike?
                </div>
                <div className="flex items-center justify-center bg-[#f7f8fa] gap-4 leading-[140%] lg:p-3 lg:text-[16px] min-w-[450px] p-[10px] rounded-[50px] text-[#1F2022] text-[14px]">
                  <img src={AvatarRow4} alt="Avatar." />
                  How did the new feature affect engagement?
                </div>
                <div className="flex items-center justify-center bg-[#f7f8fa] gap-4 leading-[140%] lg:p-3 lg:text-[16px] min-w-[450px] p-[10px] rounded-[50px] text-[#1F2022] text-[14px]">
                  <img src={AvatarRow} alt="Avatar." />
                  What's behind the dip in social media referrals?  
                </div>
              </div>

              <div className="flex gap-4 my-4 whitespace-nowrap right-blinking-text animate-slide-right">
                <div className="flex items-center justify-center bg-[#f7f8fa] gap-4 leading-[140%] lg:p-3 lg:text-[16px] min-w-[450px] p-[10px] rounded-[50px] text-[#1F2022] text-[14px]">
                  <img src={AvatarRow4} alt="Avatar." />
                  Why did Daily Active Users go down last week?
                </div>
                <div className="flex items-center justify-center bg-[#f7f8fa] gap-4 leading-[140%] lg:p-3 lg:text-[16px] min-w-[450px] p-[10px] rounded-[50px] text-[#1F2022] text-[14px]">
                  <img src={AvatarRow} alt="Avatar." />
                  Why is the retention rate going down?
                </div>
                <div className="flex items-center justify-center bg-[#f7f8fa] gap-4 leading-[140%] lg:p-3 lg:text-[16px] min-w-[450px] p-[10px] rounded-[50px] text-[#1F2022] text-[14px]">
                  <img src={AvatarRow2} alt="Avatar." />
                  Why are we seeing a sudden spike in users?
                </div>
                <div className="flex items-center justify-center bg-[#f7f8fa] gap-4 leading-[140%] lg:p-3 lg:text-[16px] min-w-[450px] p-[10px] rounded-[50px] text-[#1F2022] text-[14px]">
                  <img src={AvatarRow3} alt="Avatar." />
                  Why is app session duration falling?
                </div>
                <div className="flex items-center justify-center bg-[#f7f8fa] gap-4 leading-[140%] lg:p-3 lg:text-[16px] min-w-[450px] p-[10px] rounded-[50px] text-[#1F2022] text-[14px]">
                  <img src={AvatarRow4} alt="Avatar." />
                  Why is the landing page's bounce rate higher?
                </div>
                <div className="flex items-center justify-center bg-[#f7f8fa] gap-4 leading-[140%] lg:p-3 lg:text-[16px] min-w-[450px] p-[10px] rounded-[50px] text-[#1F2022] text-[14px]">
                  <img src={AvatarRow} alt="Avatar." />
                  Why did app uninstalls surge post-update?
                </div>
                <div className="flex items-center justify-center bg-[#f7f8fa] gap-4 leading-[140%] lg:p-3 lg:text-[16px] min-w-[450px] p-[10px] rounded-[50px] text-[#1F2022] text-[14px]">
                  <img src={AvatarRow4} alt="Avatar." />
                  Why did Daily Active Users go down last week?
                </div>
                <div className="flex items-center justify-center bg-[#f7f8fa] gap-4 leading-[140%] lg:p-3 lg:text-[16px] min-w-[450px] p-[10px] rounded-[50px] text-[#1F2022] text-[14px]">
                  <img src={AvatarRow} alt="Avatar." />
                  Why is the retention rate going down?
                </div>
                <div className="flex items-center justify-center bg-[#f7f8fa] gap-4 leading-[140%] lg:p-3 lg:text-[16px] min-w-[450px] p-[10px] rounded-[50px] text-[#1F2022] text-[14px]">
                  <img src={AvatarRow2} alt="Avatar." />
                  Why are we seeing a sudden spike in users?
                </div>
                <div className="flex items-center justify-center bg-[#f7f8fa] gap-4 leading-[140%] lg:p-3 lg:text-[16px] min-w-[450px] p-[10px] rounded-[50px] text-[#1F2022] text-[14px]">
                  <img src={AvatarRow3} alt="Avatar." />
                  Why is app session duration falling?
                </div>
                <div className="flex items-center justify-center bg-[#f7f8fa] gap-4 leading-[140%] lg:p-3 lg:text-[16px] min-w-[450px] p-[10px] rounded-[50px] text-[#1F2022] text-[14px]">
                  <img src={AvatarRow4} alt="Avatar." />
                  Why is the landing page's bounce rate higher?
                </div>
                <div className="flex items-center justify-center bg-[#f7f8fa] gap-4 leading-[140%] lg:p-3 lg:text-[16px] min-w-[450px] p-[10px] rounded-[50px] text-[#1F2022] text-[14px]">
                  <img src={AvatarRow} alt="Avatar." />
                  Why did app uninstalls surge post-update?
                </div>
                
              </div>
              
              <div className="flex gap-4 my-4 whitespace-nowrap left-blinking-text animate-slide-left">
                <div className="flex items-center justify-center bg-[#f7f8fa] gap-4 leading-[140%] lg:p-3 lg:text-[16px] min-w-[450px] p-[10px] rounded-[50px] text-[#1F2022] text-[14px]">
                  <img src={AvatarRow4} alt="Avatar." />
                  What is the y/y revenue below forecast?
                </div>
                <div className="flex items-center justify-center bg-[#f7f8fa] gap-4 leading-[140%] lg:p-3 lg:text-[16px] min-w-[450px] p-[10px] rounded-[50px] text-[#1F2022] text-[14px]">
                  <img src={AvatarRow} alt="Avatar." />
                  Why is bookings down this month on the dashboard?
                </div>
                <div className="flex items-center justify-center bg-[#f7f8fa] gap-4 leading-[140%] lg:p-3 lg:text-[16px] min-w-[450px] p-[10px] rounded-[50px] text-[#1F2022] text-[14px]">
                  <img src={AvatarRow2} alt="Avatar." />
                  Why did new user sign-ups drop this quarter?       
                </div>
                <div className="flex items-center justify-center bg-[#f7f8fa] gap-4 leading-[140%] lg:p-3 lg:text-[16px] min-w-[450px] p-[10px] rounded-[50px] text-[#1F2022] text-[14px]">
                  <img src={AvatarRow3} alt="Avatar." />
                  Why are premium conversions below expectations?
                </div>
                <div className="flex items-center justify-center bg-[#f7f8fa] gap-4 leading-[140%] lg:p-3 lg:text-[16px] min-w-[450px] p-[10px] rounded-[50px] text-[#1F2022] text-[14px]">
                  <img src={AvatarRow4} alt="Avatar." />
                  Why do new vs returning customers spend differently?
                </div>
                <div className="flex items-center justify-center bg-[#f7f8fa] gap-4 leading-[140%] lg:p-3 lg:text-[16px] min-w-[450px] p-[10px] rounded-[50px] text-[#1F2022] text-[14px]">
                  <img src={AvatarRow4} alt="Avatar." />
                  What is the y/y revenue below forecast?
                </div>
                <div className="flex items-center justify-center bg-[#f7f8fa] gap-4 leading-[140%] lg:p-3 lg:text-[16px] min-w-[450px] p-[10px] rounded-[50px] text-[#1F2022] text-[14px]">
                  <img src={AvatarRow} alt="Avatar." />
                  Why is bookings down this month on the dashboard?
                </div>
                <div className="flex items-center justify-center bg-[#f7f8fa] gap-4 leading-[140%] lg:p-3 lg:text-[16px] min-w-[450px] p-[10px] rounded-[50px] text-[#1F2022] text-[14px]">
                  <img src={AvatarRow2} alt="Avatar." />
                  Why did new user sign-ups drop this quarter?       
                </div>
                <div className="flex items-center justify-center bg-[#f7f8fa] gap-4 leading-[140%] lg:p-3 lg:text-[16px] min-w-[450px] p-[10px] rounded-[50px] text-[#1F2022] text-[14px]">
                  <img src={AvatarRow3} alt="Avatar." />
                  Why are premium conversions below expectations?
                </div>
                <div className="flex items-center justify-center bg-[#f7f8fa] gap-4 leading-[140%] lg:p-3 lg:text-[16px] min-w-[450px] p-[10px] rounded-[50px] text-[#1F2022] text-[14px]">
                  <img src={AvatarRow4} alt="Avatar." />
                  Why do new vs returning customers spend differently?
                </div>


               
              </div>
            </div>
          </div>
        </section>
    )
}