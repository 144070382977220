// @ts-nocheck
import React from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import { Button } from 'antd';

export const PrivacyPolicy = () => {
  const location = useLocation();
  const path = location.pathname;

  return (
    <main className="main bg-white">
      <section className="pb-[48px] px-[24px] max-w-4xl mx-auto">
        <div className="container-custom">
          <NavLink to="/" className="flex items-center mb-3 pt-[32px]">
            <svg
              className="mr-2"
              width="44"
              height="44"
              viewBox="0 0 44 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.2561 16.4108C24.5815 16.7363 24.5815 17.2639 24.2561 17.5893L19.8453 22.0001L24.2561 26.4108C24.5815 26.7363 24.5815 27.2639 24.2561 27.5893C23.9306 27.9148 23.403 27.9148 23.0776 27.5893L18.0776 22.5893C17.7521 22.2639 17.7521 21.7363 18.0776 21.4108L23.0776 16.4108C23.403 16.0854 23.9306 16.0854 24.2561 16.4108Z"
                fill="#2C2E30"
              ></path>
            </svg>
            <h1 className="font-semibold text-[32px] text-[#1D2E54]">Privacy Policy</h1>
          </NavLink>

          <div className="privacy-content w-full px-6 py-3 space-y-6 text-[#2C2E30] leading-relaxed">
            <section>
              <h2 className="font-bold text-[24px] mb-2">1. Introduction</h2>
              <p>
                Welcome to DataScoop AI, a product from Predpect, Inc. (“we,” “our,” or “us”). We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, and safeguard your information when you use our website and services (“Services”).
              </p>
            </section>

            <section>
              <h2 className="font-bold text-[24px] mb-2">2. Information We Collect</h2>
              <p>
                We may collect various types of information from you, including:
              </p>
              <ul className="list-disc list-inside">
                <li>
                  <strong>Personal Information:</strong> When you sign up for our services, we may collect personal details such as your name, email address, organization name, and any other information you voluntarily provide.
                </li>
                <li>
                  <strong>Usage Data:</strong> We collect information about your interactions with our website and services, including IP addresses, browser type, pages visited, and time spent on our site.
                </li>
                <li>
                  <strong>Cookies and Tracking Technologies:</strong> We use cookies and similar tracking technologies to enhance your experience on our website. These technologies may collect information about your browsing behavior and preferences.
                </li>
              </ul>
            </section>

            <section>
              <h2 className="font-bold text-[24px] mb-2">3. How We Use Your Information</h2>
              <p>
                We use the information we collect for various purposes, including:
              </p>
              <ul className="list-disc list-inside">
                <li><strong>To Provide and Improve Our Services:</strong> We use your information to operate and maintain our Services, including processing your requests and transactions, providing customer support, and improving our offerings.</li>
                <li><strong>To Communicate With You:</strong> We may use your contact information to send you updates, newsletters, and promotional materials. You can opt out of these communications at any time.</li>
                <li><strong>To Analyze and Optimize:</strong> We use data analytics to understand how our Services are used, which helps us improve the user experience and identify trends.</li>
              </ul>
            </section>

            <section>
              <h2 className="font-bold text-[24px] mb-2">4. Sharing Your Information</h2>
              <p>
                We do not sell, trade, or otherwise transfer your personal information to third parties, except in the following cases:
              </p>
              <ul className="list-disc list-inside">
                <li><strong>Service Providers:</strong> We may share your information with third-party service providers who assist us in operating our website and providing our Services, as long as they agree to keep your information confidential.</li>
                <li><strong>Legal Requirements:</strong> We may disclose your information if required by law or in response to legal requests.</li>
              </ul>
            </section>

            <section>
              <h2 className="font-bold text-[24px] mb-2">5. Data Security</h2>
              <p>
                We implement industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, please be aware that no method of transmission over the Internet or method of electronic storage is completely secure.
              </p>
            </section>

            <section>
              <h2 className="font-bold text-[24px] mb-2">6. Your Rights</h2>
              <p>
                You have the right to:
              </p>
              <ul className="list-disc list-inside">
                <li><strong>Access Your Information:</strong> You can request a copy of the personal information we hold about you.</li>
                <li><strong>Correct Your Information:</strong> You can ask us to correct any inaccuracies in your personal information.</li>
                <li><strong>Delete Your Information:</strong> You can request that we delete your personal information, subject to certain exceptions.</li>
                <li><strong>Opt-Out of Marketing:</strong> You can opt out of receiving marketing communications from us at any time by following the unsubscribe instructions in the emails we send you.</li>
              </ul>
            </section>

            <section>
              <h2 className="font-bold text-[24px] mb-2">7. Cookies and Tracking Technologies</h2>
              <p>
                You can manage your cookie preferences through your browser settings. Please note that disabling cookies may affect the functionality of our Services.
              </p>
            </section>

            <section>
              <h2 className="font-bold text-[24px] mb-2">8. Third-Party Links</h2>
              <p>
                Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these third-party sites. We encourage you to review their privacy policies before providing any personal information.
              </p>
            </section>

            <section>
              <h2 className="font-bold text-[24px] mb-2">9. Changes to This Privacy Policy</h2>
              <p>
                We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the new policy on our website. Your continued use of the Services after any changes constitutes your acceptance of the new Privacy Policy.
              </p>
            </section>

            <section>
              <h2 className="font-bold text-[24px] mb-2">10. Contact Us</h2>
              <p>
                If you have any questions about this Privacy Policy or our privacy practices, please contact us at hello@datascoop.ai.
              </p>
            </section>
          </div>
        </div>
      </section>
    </main>
  );
};

export default PrivacyPolicy;
