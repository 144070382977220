import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { setReports } from "../../store/reportsSlice";
import { HomeDatasetItem } from "./homeDatasetItem";
import { Tooltip } from "antd";
// import axios from "axios";

// const reportsApiUrl = '/api/v1/reports/';

type ReportType = {
  report_id: number,
  report_name: string,
  is_dataset: boolean,
  org_id: number | null,
}

export const HomeDatasetsTab = () => {
  const reports = useSelector((store: RootState) => store.reports.reports)
  const user = useSelector((store: RootState) => store.user.user)
  // @ts-ignore
  const subscribedReports = useSelector((store: RootState) => store.user?.user?.reports_subscribed || [])
  // @ts-ignore
  const subscribedIds = subscribedReports.map(item => item.report_id)
  const dispatch = useDispatch();

   // Filter datasets based on the specified conditions
   const filteredDatasets = reports.filter((report: ReportType) => 
    report.is_dataset && (report.org_id === null || report.org_id === user.org_id)
  );

  // all reports for dev needs
  // const fetchReports = async () => {
  //   const response = await axios(reportsApiUrl)
  //   const data = response.data;
  //   dispatch(setReports(data))
  // }

  useEffect(() => {
    // fetchReports();
  }, [])

  const handleRemoveReport = (reportId: number) => {
    console.log(`Remove report with ID ${reportId}`);
    // @ts-ignore
    dispatch(setReports(reports.filter((report: ReportType) => report.report_id !== reportId)))
  };

  return (
    <div className="max-w-[864px] w-full accent-block">
      <h2 className="font-semibold border-b border-b-[#E6E6E7] py-6 text-[#1D2E54] text-[20px] text-center">
        Datasets
        <Tooltip title="Your organization's datasets that are available to you">
          <svg
            className="ml-1 cursor-help inline-block"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_504_9467)">
              <path
                d="M6.06 6.00016C6.21674 5.55461 6.5261 5.1789 6.93331 4.93958C7.34051 4.70027 7.81927 4.61279 8.28479 4.69264C8.75031 4.77249 9.17255 5.01451 9.47672 5.37585C9.78089 5.73718 9.94737 6.19451 9.94667 6.66683C9.94667 8.00016 7.94667 8.66683 7.94667 8.66683M8 11.3335H8.00667M14.6667 8.00016C14.6667 11.6821 11.6819 14.6668 8 14.6668C4.3181 14.6668 1.33334 11.6821 1.33334 8.00016C1.33334 4.31826 4.3181 1.3335 8 1.3335C11.6819 1.3335 14.6667 4.31826 14.6667 8.00016Z"
                stroke="#AAABAC"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_504_9467">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Tooltip>
      </h2>
      
      <div className="px-6">
        {filteredDatasets.map((report: ReportType) => (
          <HomeDatasetItem
            key={report?.report_id}
            name={report?.report_name}
            subscribed={subscribedIds.includes(report?.report_id)}
            // @ts-ignore
            report={report}
            onRemoveClick={() => handleRemoveReport(report.report_id)}
          />
        ))}
      </div>

    </div>
  );
};
