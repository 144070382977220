import { useEffect, useState } from "react";
import { ArrowRightIcon } from "../../../components/UI/svgIcons";
import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";

interface SecureCodeStepProps {
  email: string;
  secureCode: string;
  setSecureCode: React.Dispatch<React.SetStateAction<string>>;
  nextStep: () => void;
  userPool: CognitoUserPool;
}

export const SecureCodeStep = ({
  email,
  secureCode,
  setSecureCode,
  nextStep,
  userPool,
}: SecureCodeStepProps) => {
  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const [error, setError] = useState("");
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null);

  useEffect(() => {
    const userData = {
      Username: email,
      Pool: userPool,
    };
    setCognitoUser(new CognitoUser(userData));
  }, [email, userPool]);

  const handleChange = (index: number, value: string) => {
    if (/^\d?$/.test(value)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);
      const enteredCode = newCode.join("");
      console.log(`Current secure code: ${enteredCode}`);
      if (value && index < code.length - 1) {
        const nextInput = document.getElementById(`secure-num-${index + 1}`);
        nextInput?.focus();
      }
    }
  };

  const handleNext = () => {
    const enteredCode = code.join("");
    if (enteredCode.length === 6) {
      setSecureCode(enteredCode);
      nextStep();
    } else {
      setError("Please enter the complete 6-digit code.");
    }
  };

  const resendCode = async () => {
    if (cognitoUser) {
      try {
        await new Promise<void>((resolve, reject) => {
          cognitoUser.forgotPassword({
            onSuccess: () => {
              console.log("Code resent successfully");
              resolve();
            },
            onFailure: (err) => {
              console.error("Error resending code:", err);
              setError("Failed to resend code. Please try again.");
              reject(err);
            },
          });
        });
      } catch (err) {
        console.error("An error occurred:", err);
        setError("An error occurred. Please try again later.");
      }
    } else {
      setError("An error occurred while retrieving the Cognito user.");
    }
  };

  return (
    <>
      <div className="flex items-center relative justify-between mb-10">
        {/* <!-- Row decor --> */}
        <div className="w-full absolute bg-[#E6E6E7] h-[1px] top-[15px] z-0"></div>
        {/* <!-- Items --> */}
        <div className="flex items-center relative bg-[#ffffff] flex-col overflow-hidden z-[1] px-[25px]">
          <div className="flex items-center justify-center font-medium h-[32px] leading-[140%] mb-2 rounded-full text-[14px] w-[32px] bg-[#F3F2FF] text-[#1D2E54]">
            <svg
              width="16"
              height="11"
              viewBox="0 0 16 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.2559 0.410826C15.5814 0.736263 15.5814 1.2639 15.2559 1.58934L6.08926 10.756C5.76382 11.0814 5.23618 11.0814 4.91074 10.756L0.744078 6.58934C0.418641 6.2639 0.418641 5.73626 0.744078 5.41083C1.06951 5.08539 1.59715 5.08539 1.92259 5.41083L5.5 8.98824L14.0774 0.410826C14.4028 0.0853888 14.9305 0.0853888 15.2559 0.410826Z"
                fill="#445082"
              />
            </svg>
          </div>
          <div className="font-medium text-[#1D2E54] text-[12px]">
            Enter Details
          </div>
        </div>
        <div className="flex items-center relative bg-[#ffffff] flex-col overflow-hidden z-[1]">
          <div className="flex items-center justify-center font-medium h-[32px] leading-[140%] mb-2 rounded-full text-[14px] w-[32px] bg-[#445082] text-[#F4F6F8]">
            2
          </div>
          <div className="font-medium text-[#1D2E54] text-[12px]">
            Verify Code
          </div>
        </div>
        <div className="flex items-center relative bg-[#ffffff] flex-col overflow-hidden z-[1] px-[25px]">
          <div className="flex items-center justify-center font-medium h-[32px] leading-[140%] mb-2 rounded-full text-[14px] w-[32px] bg-[#F3F2FF] text-[#1D2E54]">
            3
          </div>
          <div className="font-medium text-[#1D2E54] text-[12px]">
            Change Password
          </div>
        </div>
      </div>
      <div className="mb-6">
        <div className="mb-[40px]">
          <label className="font-medium text-[14px] inline-block mb-[6px] text-[#2C2E30]">
            Secure Code
          </label>
          <div className="flex items-center gap-1">
            {code.map((digit, index) => (
              <input
                key={index}
                id={`secure-num-${index}`}
                className="font-semibold text-[#2B3C6B] border border-[#AAABAC] h-[64px] leading-[120%] p-2 placeholder:text-[#AAABAC] rounded-xl secure-num text-[40px] text-center w-[77px]"
                type="text"
                maxLength={1}
                value={digit}
                onChange={(e) => handleChange(index, e.target.value)}
              />
            ))}
          </div>
        </div>
        {error && <div className="text-red-500 mb-4">{error}</div>}
        <div className="flex justify-center mb-6">
          <button
            className="font-semibold text-[#2B3C6B] text-[14px] underline underline-offset-4"
            type="button"
            onClick={resendCode}
          >
            Resend the Code
          </button>
        </div>
        <button
          className="w-full accent-btn p-3"
          type="button"
          onClick={handleNext}
        >
          Next
          <ArrowRightIcon iconClass="ml-3" />
        </button>
      </div>
    </>
  );
};
