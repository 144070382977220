//@ts-ignore
import videoSrc from 'assets/video/Narrative_Demo.mp4';
import { Link } from 'react-router-dom';

export const IntroSection = () => {
    return (
        <section id="home" className="bg-[#f7f8fa] lg:pt-[140px] pt-[60px] ">
          <div className="flex items-center flex-col justify-between gap-[32px] xl:flex-row xl:gap-[20px] container-custom">
            <div className="lg:pl-10 max-w-[712px]">
              <h1 className="font-semibold leading-[120%] mb-3 lg:mb-4 lg:text-[42px] text-[#151618] text-[32px]">
              Discover <span className="text-[#445082]">Why</span> KPIs are changing
              </h1>
              <div className="leading-[130%] text-[16px] lg:text-[20px] text-[#45484C] lg:mb-[48px] mb-10">
                Combine external signals with internal data to explain KPI fluctuations
              </div>
              <div className="flex items-center gap-3 flex-col lg:flex-row lg:max-w-[unset] lg:mx-[unset] max-w-[344px] mb-4 mx-auto">
                <Link to="/book" className="flex items-center justify-center font-semibold leading-[140%] duration-300 ease-in-out rounded-xl text-center bg-[#445082] hover:bg-[#2b3c6b] text-[#ffffff] lg:h-[50px] lg:min-w-[180px] min-w-full p-3 text-[17px]">
                  Demo with Founder
                </Link>
              </div>
            </div>
            <video 
              className="lg:block max-w-[477px] w-[100%] pr-10" 
              src={videoSrc} 
              controls
              autoPlay
              muted
              loop
              playsInline
            >
              Your browser does not support the video tag.
            </video>
          </div>
        </section>
    )
}