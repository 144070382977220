// @ts-nocheck
import React from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import { Button } from 'antd';

export const Terms = () => {
  const location = useLocation();
  const path = location.pathname;

  return (
    <main className="main bg-white">
      <section className="pb-[48px] px-[24px] max-w-4xl mx-auto">
        <div className="container-custom">
          <NavLink to="/" className="flex items-center mb-3 pt-[32px]">
            <svg
              className="mr-2"
              width="44"
              height="44"
              viewBox="0 0 44 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.2561 16.4108C24.5815 16.7363 24.5815 17.2639 24.2561 17.5893L19.8453 22.0001L24.2561 26.4108C24.5815 26.7363 24.5815 27.2639 24.2561 27.5893C23.9306 27.9148 23.403 27.9148 23.0776 27.5893L18.0776 22.5893C17.7521 22.2639 17.7521 21.7363 18.0776 21.4108L23.0776 16.4108C23.403 16.0854 23.9306 16.0854 24.2561 16.4108Z"
                fill="#2C2E30"
              ></path>
            </svg>
            <h1 className="font-semibold text-[32px] text-[#1D2E54]">Terms and Conditions</h1>
          </NavLink>

          <div className="terms-content w-full px-6 py-3 space-y-6 text-[#2C2E30] leading-relaxed">
            <section>
              <h2 className="font-bold text-[24px] mb-2">1. Introduction</h2>
              <p>
                Welcome to DataScoop AI, a product from Predpect, Inc. (“we,” “our,” or “us”). By accessing or using our website, products, and services (“Services”), you agree to be bound by these Terms and Conditions (“Terms”). If you do not agree with these Terms, you must not use our Services.
              </p>
            </section>

            <section>
              <h2 className="font-bold text-[24px] mb-2">2. Use of Services</h2>
              <p>
                You agree to use the Services only for lawful purposes and in a manner consistent with these Terms. You must not misuse our Services by interfering with their normal operation or attempting to access them using a method other than through the interfaces and instructions that we provide.
              </p>
            </section>

            <section>
              <h2 className="font-bold text-[24px] mb-2">3. Account Registration</h2>
              <p>
                To access certain features of our Services, you may be required to register an account. You agree to provide accurate, current, and complete information during the registration process and to keep your account information updated. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
              </p>
            </section>

            <section>
              <h2 className="font-bold text-[24px] mb-2">4. Payment and Billing</h2>
              <p>
                By selecting a paid plan, you agree to pay the fees associated with that plan. Payments will be billed on an annual basis unless otherwise stated. You authorize DataScoop AI to charge your payment method for all applicable fees. If your payment method fails, we may suspend or terminate your access to the Services.
              </p>
            </section>

            <section>
              <h2 className="font-bold text-[24px] mb-2">5. Data Security</h2>
              <p>
                DataScoop AI is committed to protecting your data. When you activate DataScoop inside your virtual private cloud, your data remains within your organization’s network. We implement industry-standard security measures to safeguard your data but cannot guarantee absolute security.
              </p>
            </section>

            <section>
              <h2 className="font-bold text-[24px] mb-2">6. Intellectual Property</h2>
              <p>
                All content, trademarks, and other intellectual property available on the website or through our Services are the property of DataScoop AI or its licensors. You are granted a limited, non-exclusive, non-transferable license to use our Services in accordance with these Terms. You must not reproduce, distribute, or create derivative works of our content without prior written consent.
              </p>
            </section>

            <section>
              <h2 className="font-bold text-[24px] mb-2">7. User Content</h2>
              <p>
                You may submit content to our Services, including data and feedback. By submitting content, you grant DataScoop AI a non-exclusive, worldwide, royalty-free, and transferable license to use, reproduce, modify, and display the content in connection with providing our Services.
              </p>
            </section>

            <section>
              <h2 className="font-bold text-[24px] mb-2">8. Third-Party Services</h2>
              <p>
                Our website may include links to third-party websites or services that are not owned or controlled by DataScoop AI. We are not responsible for the content, privacy policies, or practices of any third-party websites or services.
              </p>
            </section>

            <section>
              <h2 className="font-bold text-[24px] mb-2">9. Termination</h2>
              <p>
                We may suspend or terminate your access to the Services at any time, with or without cause, and with or without notice. Upon termination, you must cease all use of the Services and any licenses granted to you under these Terms will be immediately revoked.
              </p>
            </section>

            <section>
              <h2 className="font-bold text-[24px] mb-2">10. Disclaimer of Warranties</h2>
              <p>
                Our Services are provided “as is” without warranties of any kind, either express or implied. We do not warrant that the Services will be uninterrupted, error-free, or completely secure.
              </p>
            </section>

            <section>
              <h2 className="font-bold text-[24px] mb-2">11. Limitation of Liability</h2>
              <p>
                To the fullest extent permitted by law, DataScoop AI shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, or goodwill, arising out of your access to or use of our Services.
              </p>
            </section>

            <section>
              <h2 className="font-bold text-[24px] mb-2">12. Governing Law</h2>
              <p>
                These Terms are governed by and construed in accordance with the laws of Santa Clara County, California, USA, without regard to its conflict of laws principles. You agree to submit to the personal and exclusive jurisdiction of the courts located within Santa Clara County, California, USA.
              </p>
            </section>

            <section>
              <h2 className="font-bold text-[24px] mb-2">13. Changes to the Terms</h2>
              <p>
                We reserve the right to modify these Terms at any time. We will provide notice of any changes by posting the new Terms on our website. Your continued use of the Services after any such changes constitutes your acceptance of the new Terms.
              </p>
            </section>

            <section>
              <h2 className="font-bold text-[24px] mb-2">14. Contact Information</h2>
              <p>
                If you have any questions about these Terms, please contact us at hello@datascoop.ai.
              </p>
            </section>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Terms;