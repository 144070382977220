// @ts-nocheck
import { AreaChart } from "@tremor/react";
import {
  DimensionsIcon,
  FilterIcon,
  MetricsIcon,
  TotalIcon,
} from "../../components/UI/svgIcons";
import { OverviewTable } from "./overviewTable";
import { CardOverview } from "./cardOverview";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useEffect, useState } from "react";
import { Segmented, Tabs } from "antd";


interface DataByDate {
  date: string;
  value: number;
}

interface Report {
  name: string;
  expectedChangePercentage: number | null;
  aggregationMethod: string;
  columnName: string;
  singularMetric: any;
  baselineDateRange: string[];
  baselineNumRows: number;
  baselineValue: number;
  baselineValueByDate: DataByDate[];
  comparisonDateRange: string[];
  comparisonNumRows: number;
  comparisonValue: number;
  comparisonValueByDate: DataByDate[];
  dimensionSliceInfo: any;
  dimensions: any;
  filters: any[];
  id: string;
  keyDimensions: string[];
  topDriverSliceKeys: string[];
  totalSegments: number;
}

interface ReportsState {
  [key: string]: Report;
}

const operators = {
  eq: '=',
  neq: '=/=',
  empty: 'Empty',
  non_empty: 'Not Empty',
}

console.log('Executing OverviewTab.tsx')

export const OverviewTab = ({ isShown }) => {

  const resultTime = useSelector((store: RootState) => store.reports.resultTime)
  const result = useSelector((store: RootState) => store.reports.result) as ReportsState;
  const filters = useSelector((store: RootState) => store.reports.report.config.settings);

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const transformData = (baseline: DataByDate[], comparison: DataByDate[]) => {
    return baseline.map((baselineEntry, index) => {
      const comparisonEntry = comparison[index];
      const combinedDate = `${formatDate(baselineEntry.date)} / ${comparisonEntry ? formatDate(comparisonEntry.date) : ''}`;
      return {
        date: combinedDate,
        Base: baselineEntry.value,
        Comparison: comparisonEntry ? comparisonEntry.value : 0
      };
    });
  };

  const transformDifferenceData = (baseline: DataByDate[], comparison: DataByDate[]) => {
    return baseline.map((baselineEntry, index) => {
      const comparisonEntry = comparison[index];
      if(baselineEntry.value === 0) return null;
      const difference = comparisonEntry ? ((comparisonEntry.value - baselineEntry.value) / baselineEntry.value) * 100 : 0;
      const combinedDate = `${formatDate(baselineEntry.date)} / ${comparisonEntry ? formatDate(comparisonEntry.date) : ''}`;
      return {
        date: combinedDate,
        Difference: difference
      };
    })?.filter(itm => itm);
  };


  const dynamicKey = Object.keys(result)[0];
  const resultKeys = Object.keys(result);
  const [chart1key, setChart1Key] = useState(dynamicKey)
  const [chart2key, setChart2Key] = useState(dynamicKey)
  const keyDimensions = result[dynamicKey].keyDimensions;
  const contentString = keyDimensions.join(', ');

  useEffect(() => {
    setChart1Key(dynamicKey)
    setChart2Key(dynamicKey)
  }, [dynamicKey])

  const baselineValue = result[dynamicKey].baselineValue;
  const comparisonValue = result[dynamicKey].comparisonValue;
  const baselineNumRows = result[dynamicKey].baselineNumRows;
  const comparisonNumRows = result[dynamicKey].comparisonNumRows;

  const difference = comparisonValue - baselineValue;
  const percentageDifference = baselineValue !== 0 ? (((comparisonValue - baselineValue) / baselineValue) * 100).toFixed(2) : 0;

  const differenceRows = comparisonNumRows - baselineNumRows;
  const percentageDifferenceRows = baselineNumRows !== 0 ? (((comparisonNumRows - baselineNumRows) / baselineNumRows) * 100).toFixed(2) : 0;

  const dayByDayValueData = transformData(
    result[chart1key] ? result[chart1key].baselineValueByDate : result[dynamicKey].baselineValueByDate,
    result[chart1key] ? result[chart1key]?.comparisonValueByDate : result[dynamicKey]?.comparisonValueByDate
  );

  const dayByDayDifferenceData = transformDifferenceData(
    result[chart2key] ? result[chart2key].baselineValueByDate : result[dynamicKey].baselineValueByDate,
    result[chart2key] ? result[chart2key]?.comparisonValueByDate : result[dynamicKey]?.comparisonValueByDate
  );

  const formatDateRange = (dateRange: string[]) => {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' };
    const startDate = new Date(dateRange[0]).toLocaleDateString('en-US', options);
    const endDate = new Date(dateRange[1]).toLocaleDateString('en-US', options);
    return `${startDate} to ${endDate}`;
  };


  const comparisonDateRange = result[dynamicKey].comparisonDateRange;
  const comparisonPeriod = formatDateRange(comparisonDateRange);

  const baseDateRange = result[dynamicKey].baselineDateRange;
  const basePeriod = formatDateRange(baseDateRange);


  const dataFormatter = (number: number) =>
    `${Intl.NumberFormat('us').format(number).toString()}`;

  const filtersAndDimensions = {
    filters: [],
    dimensions: []
  }
  Object.values(result).forEach((res) => {
    let prefix = '';
    if (res.name.includes('numerator') && res.filters?.length) {
      prefix = 'Numerator: '
    }
    if (res.name.includes('denominator') && res.filters?.length) {
      prefix = 'Denominator: '
    }
    if (res.filters?.length) {
      filtersAndDimensions.filters.push(`${prefix || ''}${res.filters ? res.filters?.map?.(filter => `${filter.column} ${operators[filter.operator]} ${filter.values?.join?.(', ') || ''}`)?.join?.('\n') : ''}`)
    }
    if (!prefix) {
      filtersAndDimensions.dimensions.push(Object.values(res.dimensions)?.map?.(dimension => `${dimension.name}`)?.join?.(', '))
    }
  })

  const getMetricsCardData = () => {
    const resArray = [];
    if (resultKeys.length < 2) {
      return `${result[dynamicKey].name}`;
    }
    if (result[dynamicKey] && result[dynamicKey].name) {
      resArray.push(`Metric: ${result[dynamicKey].name}`);
    }
    Object.values(result).forEach((res) => {
      if (res.name.includes('numerator')) {
        resArray.push(
          `Numerator: ${filters.ratio.numType}.${filters.ratio.numCol}`
        )
      }
      if (res.name.includes('denominator')) {
        resArray.push(
          `Denominator: ${filters.ratio.denType}.${filters.ratio.denCol}`
        )
      }
    })
    return resArray.join('\n')
  }

  if (!isShown) return null;


  return (
    <>
      {/* <!--Tabs content--> */}
      <div className="mb-[32px]">
        {/* <!-- Cards --> */}
        <div className="flex items-center justify-between gap-8 mb-[32px]">
          <CardOverview icon={<MetricsIcon iconClass="mr-2" />} title="Metrics" content={getMetricsCardData()} />
          <CardOverview icon={<DimensionsIcon iconClass="mr-2" />} title="Dimensions" subContent={`${Object.values(result[dynamicKey].dimensions).length} in total`}
            content={filtersAndDimensions.dimensions[0] || 'None Selected'} />
          {/* @ts-ignore */}
          <CardOverview icon={<FilterIcon iconClass="mr-2" />} title="Filter" content={filtersAndDimensions.filters.join('\n') || 'None Selected'} />
          <CardOverview icon={<TotalIcon iconClass="mr-2" />} title="Total Segments" content={<><div className="font-medium text-[16px] leading-[150%] text-[#35383B] mb-6">
            {result[dynamicKey].totalSegments.toLocaleString('en-US')}
          </div>
            <div className="font-medium text-[16px] leading-[150%] text-[#35383B]">
              {resultTime && `Analyzed in ${Number(resultTime / 1000).toFixed(2)} second(s)`}
            </div>
          </>
          }
          />

        </div>
        <OverviewTable
          differenceRows={differenceRows}
          percentageDifferenceRows={percentageDifferenceRows}
          difference={difference}
          percentageDifference={percentageDifference}
          baselineValue={baselineValue.toLocaleString('en-US')}
          comparisonValue={comparisonValue.toLocaleString('en-US')}
          aggregationMethods={Object.keys(result)}
          basePeriod={basePeriod}
          comparisonPeriod={comparisonPeriod}
          baseRows={baselineNumRows.toLocaleString('en-US')}
          comparisonRows={comparisonNumRows.toLocaleString('en-US')}
          // @ts-ignore
          comparisonAggregationValues={resultKeys.map(key => {
            const baselineValueTemp = result[key].baselineValue;
            const comparisonValueTemp = result[key].comparisonValue;
            return ({
              value: comparisonValueTemp.toLocaleString('en-US'),
              difference: comparisonValueTemp - baselineValueTemp,
              percentageDifference: baselineValueTemp !== 0 ? (((comparisonValueTemp - baselineValueTemp) / baselineValueTemp) * 100).toFixed(2) : 0,
            })
          })}
          baseAggregationValues={resultKeys.map(key => {
            const baselineValueTemp = result[key].baselineValue;
            const comparisonValueTemp = result[key].comparisonValue;
            return ({
              value: baselineValueTemp.toLocaleString('en-US'),
              difference: comparisonValueTemp - baselineValueTemp,
              percentageDifference: baselineValueTemp !== 0 ? (((comparisonValueTemp - baselineValueTemp) / baselineValueTemp) * 100).toFixed(2) : 0,
            })
          })}
        />
        {/* <!-- Chart --> */}
        <div className="border border-[#E6E6E7] p-6 shadow-md w-full mb-[32px] rounded-[28px]">
          <div className="mb-6">
            <h3 className="font-semibold leading-[130%] text-[20px] mb-6 text-[#1D2E54]">
              Day by Day Value
            </h3>
            <div className="inline font-semibold text-[#7F8ACA] border-b-2 border-b-[#7F8ACA] pb-2 text-[14px]">
              <Tabs defaultActiveKey="1" items={
                resultKeys.map(key => ({
                  key,
                  label: result[key].name,
                }))
              }
                onChange={(key) => setChart1Key(key)}
                className="custom-tabs"
                />
            </div>
          </div>
          <AreaChart
            className="max-h-[240px]"
            data={dayByDayValueData}
            index="date"
            categories={['Base', 'Comparison']}
            colors={["#7F8ACA", "#2b3c6b"]}
            valueFormatter={dataFormatter}
            yAxisWidth={78}
            showAnimation={true}
            onValueChange={(v) => console.log(v)}
          />
        </div>
        {/* <!-- Chart --> */}
        <div className="border border-[#E6E6E7] p-6 shadow-md w-full mb-[32px] rounded-[28px]">
          <div className="mb-6">
            <h3 className="font-semibold leading-[130%] text-[20px] mb-6 text-[#1D2E54]">
              Day by Day Difference
            </h3>
            <div className="inline font-semibold text-[#7F8ACA !important] border-b-2 border-b-[#445082] pb-2 text-[14px]">
              <Tabs defaultActiveKey="1" items={
                resultKeys.map(key => ({
                  key,
                  label: result[key].name,
                }))
              }
                onChange={(key) => setChart2Key(key)} 
                className="custom-tabs" />
            </div>
          </div>
          <AreaChart
            data={dayByDayDifferenceData}
            index="date"
            categories={["Difference"]}
            colors={["#2b3c6b"]}
            className="max-h-[240px]"
            yAxisWidth={78}
            showAnimation={true}
            valueFormatter={(value) => `${value.toFixed(2)}%`}
          />
        </div>
      </div>
    </>
  )
}