// @ts-nocheck
import { useContext, useEffect, useState } from "react";
import { CloseIcon } from "../../components/UI/svgIcons";
import { NotificationContext } from "./../../App";
import { CheckOutlined } from "@ant-design/icons";
import { fetchCustomData } from "./customizePopup";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { convert } from 'html-to-text';
import axios from "axios";
import { Popconfirm, Spin } from "antd";
// import sgMail from '@sendgrid/mail';

interface SharePopupProps {
    isOpen: boolean;
    onClose: () => void;
}

const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

const useSendGrid = () => {
    const [emailToSend, setEmailToSend] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { openNotification } = useContext(NotificationContext);
    const user = useSelector((store: RootState) => store.user.user);
    const reportName = useSelector((store: RootState) => store.reports.report_name)
    const sendMail = async (emailText) => {
        if (!validateEmail(emailToSend)) {
            openNotification('warning', 'Please input valid email', 'Email not valid', 'bottomRight')
            return
        }
        try {
            setIsLoading(true);
            await axios(`/api/v1/email/send`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                data: JSON.stringify({
                    recipient_email: emailToSend,
                    sender_name: `${user.first_name} ${user.last_name}`,
                    analysis_link: window.location.href,
                    analysis_title: reportName,
                    ...(emailText ? {
                        salient_points: emailText.split('•').slice(1)
                    } : {})
                    // "file_path": "/path/to/attachment.pdf" (optional)            
                }),
            });
            openNotification('success', 'Sent!', '', 'bottomRight')
        } catch (err) {
            openNotification('error', 'Error sending mail', '', 'bottomRight')
            console.error("Error sending mail", err);
        } finally {
            setIsLoading(false);
        }
    }

    return {
        sendMail,
        emailToSend,
        setEmailToSend,
    }
}

export const SharePopup = ({ isOpen, onClose }: SharePopupProps) => {
    const { openNotification } = useContext(NotificationContext);
    const [hasCopied, setHasCopied] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const user = useSelector((store: RootState) => store.user.user);
    const narrativeInState = useSelector((store: RootState) => store.reports.report.config.narrative)

    const {
        sendMail,
        emailToSend,
        setEmailToSend,
    } = useSendGrid()

    useEffect(() => {
        if (!isOpen) {
            setHasCopied(false)
            setEmailToSend('')
        }
    }, [isOpen])
    if (!isOpen) return null;

    const copyText = async () => {
        await navigator.clipboard.writeText(window.location.href);
        // @ts-ignore
        openNotification('success', 'Copied!', '', 'bottomRight')
        setHasCopied(true);
    }

    const sendEmail = async () => {
        setIsLoading(true)
        let emailText
        try {
            emailText = await fetchCustomData(convert(narrativeInState?.html), 'bulletedList', user)
            console.log("TEXT", emailText)
        } catch (e) {
            emailText = '';
        }
        await sendMail(emailText)
        setIsLoading(false)
        onClose();
    }
    const emailValid = validateEmail(emailToSend);
    console.log(emailValid)

    return (

        <div id="share-popup" tabIndex={-1}
            className="z-[1000] flex justify-center items-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 bottom-0 z-50 w-full md:inset-0 h-modal md:h-full bg-[#82838580]">
            <Spin spinning={isLoading}>
                <div className="accent-block relative p-10 w-full max-w-[640px] mx-4">
                    <button className="absolute top-2 end-2 p-4" id="close-share-popup" type="button" onClick={onClose}>
                        <CloseIcon />
                    </button>
                    <div className=" flex flex-col items-center">
                        <div className="text-[#1D2E54] text-[32px] font-semibold leading-[120%] mb-6">Share Analysis</div>
                        <div className="w-full">
                            <div className="flex items-end gap-2 pb-6 border-b border-b-[#E6E6E7]">
                                <div className="relative w-full">
                                    {/* <label className="text-[14px] font-medium inline-block text-[#2C2E30] mb-[6px]" htmlFor="email">Add */}
                                        {/* Email</label> */}
                                    <input className="border border-[#AAABAC] rounded-xl px-[14px] pl-[42px] py-[9px] w-[100%]" type="email"
                                        value={emailToSend} onChange={(e) => setEmailToSend(e.target.value)} name="email" id="email" placeholder="Enter recipient's Email" />
                                    <svg className="absolute bottom-[12px] left-[14px]" width="20" height="20" viewBox="0 0 20 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M17.9165 15L12.3808 10M7.61889 10L2.0832 15M1.6665 5.83337L8.4706 10.5962C9.02158 10.9819 9.29707 11.1748 9.59672 11.2495C9.86142 11.3154 10.1383 11.3154 10.403 11.2495C10.7026 11.1748 10.9781 10.9819 11.5291 10.5962L18.3332 5.83337M5.6665 16.6667H14.3332C15.7333 16.6667 16.4334 16.6667 16.9681 16.3942C17.4386 16.1545 17.821 15.7721 18.0607 15.3017C18.3332 14.7669 18.3332 14.0668 18.3332 12.6667V7.33337C18.3332 5.93324 18.3332 5.23318 18.0607 4.6984C17.821 4.22799 17.4386 3.84554 16.9681 3.60586C16.4334 3.33337 15.7333 3.33337 14.3332 3.33337H5.6665C4.26637 3.33337 3.56631 3.33337 3.03153 3.60586C2.56112 3.84554 2.17867 4.22799 1.93899 4.6984C1.6665 5.23318 1.6665 5.93324 1.6665 7.33337V12.6667C1.6665 14.0668 1.6665 14.7669 1.93899 15.3017C2.17867 15.7721 2.56112 16.1545 3.03153 16.3942C3.56631 16.6667 4.26637 16.6667 5.6665 16.6667Z"
                                            stroke="#AAABAC" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                                <Popconfirm
                                    style={{ zIndex: 10001 }}
                                    title="Email not valid" description="Please input valid email" showCancel={false} disabled={!!emailValid}>
                                    <button id="share-popup-submit"
                                        className="text-[16px] leading-[140%] duration-300 ease-in-out font-semibold h-[44px] py-[10px] rounded-xl w-[120px] bg-[#445082] hover:bg-[#2b3c6b] text-[#ffffff]"
                                        type="button"
                                        onClick={emailValid ? sendEmail : null}>
                                        Send
                                    </button>
                                </Popconfirm>
                            </div>
                            <div className="pt-6 flex justify-between items-center gap-4">
                                <button
                                    className="py-[10px] flex items text-[#2B3C6B] hover:text-[#1D2E54] text-[16px] font-semibold leading-[140%]"
                                    type="button">
                                    <svg className="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M18.3332 17.5V15.8333C18.3332 14.2801 17.2709 12.9751 15.8332 12.605M12.9165 2.7423C14.1381 3.23679 14.9998 4.43443 14.9998 5.83333C14.9998 7.23224 14.1381 8.42988 12.9165 8.92437M14.1665 17.5C14.1665 15.9469 14.1665 15.1703 13.9128 14.5577C13.5745 13.741 12.9255 13.092 12.1088 12.7537C11.4962 12.5 10.7196 12.5 9.1665 12.5H6.6665C5.11337 12.5 4.3368 12.5 3.72423 12.7537C2.90747 13.092 2.25855 13.741 1.92024 14.5577C1.6665 15.1703 1.6665 15.9469 1.6665 17.5M11.2498 5.83333C11.2498 7.67428 9.75745 9.16667 7.9165 9.16667C6.07555 9.16667 4.58317 7.67428 4.58317 5.83333C4.58317 3.99238 6.07555 2.5 7.9165 2.5C9.75745 2.5 11.2498 3.99238 11.2498 5.83333Z"
                                            stroke="#2B3C6B" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    Public to Users Team
                                </button>
                                <button
                                    className="w-[120px] h-[44px] p-[10px] text-[16px] font-semibold leading-[150%] rounded-xl bg-[#F3F2FF] hover:bg-[#D5D6FD] text-[#2B3C6B] hover:text-[#1D2E54] ease-in-out duration-300"
                                    type="button"
                                    onClick={copyText}>{!hasCopied ? 'Copy Link' : <CheckOutlined />}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Spin>
        </div>
    )
}