import { useState } from "react";
import {
  ArrowRightIcon,
  EyeCloseIcon,
  EyeOpenIcon,
  GoogleIcon,
  LogoIcon,
} from "../../../components/UI/svgIcons";
import { Input } from "../../../components/inputs/input";
import { Link } from "react-router-dom";

interface UserDataStepProps {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  password: string;
  firstName: string;
  setFirstName: React.Dispatch<React.SetStateAction<string>>;
  lastName: string;
  setLastName: React.Dispatch<React.SetStateAction<string>>;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  errorMessage: string;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  nextStep: () => void;
}

export const UserDataStep = ({
  email,
  setEmail,
  password,
  setPassword,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  nextStep,
  errorMessage,
  setErrorMessage
}: UserDataStepProps) => {
  const passwordRules = [
    { rule: "Password must contain at least 8 characters", regex: /.{8,}/ },
    { rule: "Password must contain an upper case letter", regex: /[A-Z]/ },
    { rule: "Password must contain a lower case letter", regex: /[a-z]/ },
    { rule: "Password must contain a number", regex: /\d/ },
    {
      rule: "Password must contain a special character or a space",
      regex: /[!@#$%^&* ]/,
    },
    {
      rule: "Password must not contain a leading or trailing space",
      regex: /^(?! ).*(?<! )$/,
    },
  ];

  const [validationResults, setValidationResults] = useState(
    Array(passwordRules.length).fill(false)
  );
  const [isActive, setIsActive] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
  });

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
    };

    if (!email) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!emailRegex.test(email)) {
      newErrors.email = "Not a valid email";
      valid = false;
    }

    if (!password) {
      newErrors.password = "Password is required";
      valid = false;
    }

    if (!firstName) {
      newErrors.firstName = "First name is required";
      valid = false;
    }

    if (!lastName) {
      newErrors.lastName = "Last name is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value;
    setPassword(newPassword);

    const newValidationResults = passwordRules.map((ruleObj) =>
      ruleObj.regex.test(newPassword)
    );
    setValidationResults(newValidationResults);

    if (!isActive) {
      setIsActive(true);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (validateForm()) {
      nextStep();
    } else {
      console.log("Form has errors");
    }
  };

  return (
    <div className="wrapper">
      <main className="main flex-wrapper">
        <section className="">
          <div className="container-custom flex justify-center items-center">
            <div style={{
              width: 'calc(100vw - 32px)'
            }} className="accent-block py-6 px-10 max-w-[560px] my-4">
              <div className="flex flex-col items-center mb-10">
                <Link to="/">
                  <LogoIcon iconClass="mb-6 cursor-pointer" />
                </Link>
                <h1 className="accent-title mb-3 text-center">Welcome to DataScoop</h1>
                <div className="text-[#45484C] text-[16px]">
                  Please enter your details.
                </div>
              </div>
              <form action="" className="mb-4" onSubmit={handleSubmit}>
                <div className="flex items-center gap-[20px] mb-[20px]">
                  <div className="relative">
                    <Input
                      label="First Name"
                      htmlFor="first-name"
                      name="first-name"
                      id="first-name"
                      type="text"
                      placeholder="Enter your first name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                     
                    />
                    {errors.firstName && (
                      <div className="error text-red-500 mt-[5px] text-sm">{errors.firstName}</div>
                    )}
                  </div>
                  <div className="relative">
                    <Input
                      label="Last Name"
                      htmlFor="last-name"
                      name="last-name"
                      id="last-name"
                      type="text"
                      placeholder="Enter your last name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                     
                    />
                    {errors.lastName && (
                      <div className="error text-red-500 mt-[5px] text-sm">{errors.lastName}</div>
                    )}
                  </div>
                </div>
                <div className="relative mb-[20px]">
                  <Input
                    label="Email"
                    htmlFor="email"
                    name="email"
                    id="email"
                    type="text"
                    placeholder="Enter your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  
                  />
                  {errors.email && (
                    <div className="error text-red-500 mt-[5px] text-sm">{errors.email}</div>
                  )}
                  {errorMessage && (
                    <div className="error text-red-500 mt-[5px] text-sm">{errorMessage}</div>
                  )}
                </div>
                <div className="relative mb-[20px]">
                  <Input
                    label={
                      <>
                        Add Organization{" "}
                        <span className="text-[#828385]">(Optional)</span>
                      </>
                    }
                    htmlFor="organization"
                    name="organization"
                    id="organization"
                    type="text"
                    placeholder="Enter Organization Name"
                  />
                </div>
                <div className="relative mb-[24px]">
                  <Input
                    label="Password"
                    htmlFor="password"
                    name="password"
                    id="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="••••••••"
                    value={password}
                    onChange={handlePasswordChange}
                  
                  />
                  {errors.password && (
                    <div className="error text-red-500 mt-[5px] text-sm">{errors.password}</div>
                  )}
                  <div
                    className="cursor-pointer"
                    id="show-password"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <EyeCloseIcon iconClass="absolute end-[12px] select-none top-[34px] " />
                    ) : (
                      <EyeOpenIcon iconClass="absolute end-[12px] select-none top-[34px] " />
                    )}
                  </div>
                </div>
                {isActive && (
                  <ul className="text-xs mb-[24px]">
                    {passwordRules.map((ruleObj, index) => (
                      <li
                        key={index}
                        style={{
                          color: validationResults[index] ? "green" : "red",
                        }}
                      >
                        {validationResults[index]
                          ? `✓ ${ruleObj.rule}`
                          : `✖ ${ruleObj.rule}`}
                      </li>
                    ))}
                  </ul>
                )}
                <button className="accent-btn w-full p-3" type="submit">
                  Next
                  <ArrowRightIcon iconClass="ml-3" />
                </button>
              </form>
              <div className="">
                {/* <div className="relative flex justify-center mb-4">
                  <div className="w-full h-[1px] bg-[#E6E6E7] absolute top-[50%]"></div>
                  <div className="relative w-[48px] py-[7px] text-[#35383B] text-[14px] bg-[#ffffff] text-center z-[1]">
                    or
                  </div>
                </div>
                <div className="flex gap-3 mb-[32px]">
                  <button
                    className="w-full h-[44px] border border-[#AAABAC] rounded-xl bg-[#F4F6F8] hover:bg-[#D5D6FD] ease-in-out duration-300 p-[10px] flex justify-center items-center"
                    type="button"
                  >
                    <GoogleIcon />
                  </button>
                </div> */}
                <div className="text-[#35383B] text-[14px] text-center">
                  Do you have an account?
                  <a
                    href="login"
                    className="text-[#2B3C6B] font-semibold underline underline-offset-4 ml-2"
                  >
                    Sign in
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};
