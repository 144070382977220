import IllustrationFirst from "assets/images/illustration.png";
import IllustrationSecond from "assets/images/illustration-2.png";
import IllustrationThird from "assets/images/illustration-3.png";
import Narrative from 'assets/images/narrative.png';
//@ts-ignore
// import videoSrc from 'assets/video/Narrative_Demo.mp4';


export const SolutionSection = () => {
  return (
    <section id="solution" className="pt-[32px] lg:pt-0 mb-[0] mt-[0]">
      <div className="container-custom">
        {/* <div className="flex flex-col-reverse xl:flex-row items-center justify-between gap-[32px] xl:gap-[60px] mb-[80px]">
              <video width="600" controls >
                <source src={videoSrc} type="video/mp4" />
                Your browser does not support video.
              </video>
              <div className="xl:max-w-[570px]">
                <div className="font-semibold leading-[120%] text-[#1D2E54] text-[24px] mb-3 xl:mb-4 xl:text-[26px]">
                DataScoop accelerates
                Data Exploration</div>
                <div className="leading-[150%] text-[#45484C] text-[14px] xl:text-[16px]">
                DataScoop leverages the latest advancements in AI to generate the key reasons driving your business growth - it's power comes from its ability to associate KPI changes to external contexts
                </div>
              </div>
            </div> */}

        <div className="flex items-center flex-col justify-between gap-[32px] xl:flex-row xl:gap-[20px]  mb-[48px]">
          <div className="xl:max-w-[570px]">
            <div className="font-semibold leading-[120%] text-[#1D2E54] text-[24px] mb-3 xl:mb-4 xl:text-[26px]">
              Wake up to DataScoop every morning</div>
            <div className="leading-[150%] text-[#45484C] text-[14px] mb-3 xl:mb-4 xl:text-[16px]">
              DataScoop augments Business Intelligence by:
            </div>
            <ul className="flex gap-[16px] flex-col xl:gap-[20px] max-w-[544px]">
              <li className="flex gap-3 items-center leading-[150%] text-[#45484C] text-[14px] xl:text-[16px]">
                <svg className="h-[36px] min-w-[36px]" width="36" height="37" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="#F3F2FF" />
                  <g transform="scale(0.5) translate(12, 12)">
                    <path d="M23.808,20.197L15.741,1.557c-.52-1.276-1.979-1.895-3.259-1.371l-.926,.378c-.358,.146-.671,.368-.921,.649-.438-.726-1.234-1.212-2.142-1.212H2.5C1.122,0,0,1.121,0,2.5V21.5c0,1.379,1.122,2.5,2.5,2.5h5.993c1.378,0,2.5-1.121,2.5-2.5V5.689l7.265,16.79c.522,1.286,2.014,1.885,3.259,1.367l.925-.378c1.261-.486,1.906-2.026,1.366-3.271ZM12.505,6.666l3.704-1.51,5.37,12.411-3.704,1.511L12.505,6.666ZM1,6h3.993v12H1V6Zm4.993,0h4v12H5.993V6Zm4-3.5v2.5H5.993V1h2.5c.827,0,1.5,.673,1.5,1.5ZM2.5,1h2.493V5H1V2.5c0-.827,.673-1.5,1.5-1.5ZM1,21.5v-2.5h3.993v4H2.5c-.827,0-1.5-.673-1.5-1.5Zm7.493,1.5h-2.5v-4h4v2.5c0,.827-.673,1.5-1.5,1.5ZM11.934,1.489l.926-.378c.751-.31,1.642,.051,1.959,.832l.993,2.294-3.704,1.51-.997-2.304c-.312-.766,.057-1.643,.823-1.955Zm10.947,20.245c-.155,.369-.445,.656-.816,.808h0l-.925,.378c-.765,.312-1.643-.056-1.959-.829l-.907-2.096,3.704-1.511,.909,2.101c.152,.371,.15,.779-.005,1.149Z" stroke="#445082" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                  </g>
                </svg>
                <div className="">
                  Autonomously investigating all KPIs daily
                </div>
              </li>
              <li className="flex gap-3 items-center leading-[150%] text-[#45484C] text-[14px] xl:text-[16px]">
                <svg className="h-[36px] min-w-[36px]" width="36" height="37" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="#F3F2FF" />
                  <g transform="scale(0.6) translate(9, 9)">
                    <path d="M17,12h-4v-1h4v1Zm0,4h-6v1h6v-1Zm4,5.5V2h-5.05c-.232-1.14-1.242-2-2.45-2h-3c-1.208,0-2.217,.86-2.45,2H3V21.5c0,1.378,1.122,2.5,2.5,2.5h13c1.378,0,2.5-1.122,2.5-2.5ZM9,3v-.5c0-.827,.673-1.5,1.5-1.5h3c.827,0,1.5,.673,1.5,1.5v.5h5V21.5c0,.827-.673,1.5-1.5,1.5H5.5c-.827,0-1.5-.673-1.5-1.5V3h5Zm.489,8.661l3.362-3.305-.701-.713-3.365,3.308c-.056,.056-.18,.055-.242-.006l-1.779-1.721-.695,.719,1.773,1.715c.22,.22,.513,.342,.825,.342s.605-.122,.822-.339Zm-.489,4.339h-2v1h2v-1Z" stroke="#445082" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
                  </g>
                </svg>
                <div className="">
                  Contextualizing external information
                </div>
              </li>
              <li className="flex gap-3 items-center leading-[150%] text-[#45484C] text-[14px] xl:text-[16px]">
                <svg className="h-[36px] min-w-[36px]" width="36" height="37" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="#F3F2FF" />
                  <path d="M9.17,13.83l-2.734-2.734c-.281-.281-.436-.655-.436-1.052s.155-.771,.436-1.053l2.778-2.777,.707,.707-2.778,2.777c-.092,.092-.143,.215-.143,.346s.051,.253,.143,.345l2.734,2.734-.707,.707Zm7.734-3.441l-2.757,2.757,.707,.707,2.757-2.757c.58-.58,.58-1.524,0-2.105l-2.801-2.801-.707,.707,2.801,2.801c.191,.19,.191,.5,0,.691Zm7.096,5.611v2.5c0,1.378-1.122,2.5-2.5,2.5H2.5c-1.378,0-2.5-1.122-2.5-2.5v-2.5H2V3H22v13h2Zm-21,0h5.707l1,1h4.586l1-1h5.707V4H3v12Zm20,1h-7.293l-1,1h-5.414l-1-1H1v1.5c0,.827,.673,1.5,1.5,1.5H21.5c.827,0,1.5-.673,1.5-1.5v-1.5Z" stroke="#445082" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" transform="scale(0.6) translate(9, 9)" />
                </svg>
                <div className="">
                  Explaining KPI changes in a few words
                </div>
              </li>
              {/* <li className="flex gap-3 items-center leading-[150%] text-[#45484C] text-[14px] xl:text-[16px]">
                <svg className="h-[36px] min-w-[36px]" width="36" height="37" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="#FBF2FF" />
                  <g transform="scale(0.6) translate(9, 9)">
                    <path d="M17.5,11.039c-3.584,0-6.5,2.916-6.5,6.5,0,3.563,2.916,6.461,6.5,6.461s6.5-2.916,6.5-6.5c0-3.563-2.916-6.461-6.5-6.461Zm0,11.961c-3.032,0-5.5-2.45-5.5-5.461,0-3.033,2.468-5.5,5.5-5.5s5.5,2.45,5.5,5.461c0,3.033-2.468,5.5-5.5,5.5Zm1.354-4.854c.195,.195,.195,.512,0,.707-.098,.098-.226,.146-.354,.146s-.256-.049-.354-.146l-1-1c-.094-.094-.146-.221-.146-.354v-2c0-.276,.224-.5,.5-.5s.5,.224,.5,.5v1.793l.854,.854Zm.646-16.146h-1.5V.5c0-.276-.224-.5-.5-.5s-.5,.224-.5,.5v1.5H7V.5c0-.276-.224-.5-.5-.5s-.5,.224-.5,.5v1.5h-1.5C2.019,2,0,4.019,0,6.5v13c0,2.481,2.019,4.5,4.5,4.5h6c.276,0,.5-.224,.5-.5s-.224-.5-.5-.5H4.5c-1.93,0-3.5-1.57-3.5-3.5V9H23v1.5c0,.276,.224,.5,.5,.5s.5-.224,.5-.5V6.5c0-2.481-2.019-4.5-4.5-4.5ZM1,8v-1.5c0-1.93,1.57-3.5,3.5-3.5h15c1.93,0,3.5,1.57,3.5,3.5v1.5H1Z" stroke="#AD81D2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                  </g>
                </svg>
                <div className="">
                  Saves time by focusing on what matters most
                </div>
              </li> */}
            </ul>
          </div>
          <img className="w-[100%] max-w-[715px] hidden md:block" src={Narrative} alt="Mockup." />
        </div>

      </div>
    </section>
  )
}