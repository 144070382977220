// @ts-nocheck
import { NotificationContext } from "./../../App";
import { Select, Spin } from "antd";
import { useContext, useEffect, useState } from "react";
import { convert } from 'html-to-text';
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

interface CustomizePopupProps {
    isOpen: boolean;
    onClose: () => void;
}

const query = (text, name) => ({
    summarize: `query Summarize($text: String) {
 summarize(text: $text) {
   result
 }
}

`,
    bulletedList: `query BulletedList($text: String) {
 bulletedList(text: $text) {
   result
 }
}

`,
    emailWriter: `query EmailWriter($text: String, $name: String) {
 emailWriter(text: $text, name: $name) {
   result
 }
}

`,
    headline_custom: `query Headline_custom($text: String) {
 headline_custom(text: $text) {
   result
 }
}
`,
})

export const fetchCustomData = async (text, contextKey, user) => {

    const endpoint = "https://yef4c1rjra.execute-api.us-east-1.amazonaws.com/graphql?cortex-api-key=WETrGhFivOdFLx91axtaDERWdG81Ck4GrWfauUJ1dNxE0BreAdWqqp9nJSTqmgff";
    const headers = {
        "content-type": "application/json",
        // "Authorization": "<token>"
    };
    const graphqlQuery = {
        // "operationName": contextKey,
        "query": query(text)[contextKey],
        "variables": { text, "targetLength": 100, name: `${user.first_name} ${user.last_name}` }
    };
    const response = await axios({
        url: endpoint,
        method: 'post',
        headers: headers,
        data: graphqlQuery
    });
    console.log(response.data, response.data.data[contextKey].result)
    return response.data.data[contextKey].result
}

export const CustomizePopup = ({ isOpen, onClose, userContextState, narrativeData, fetchData, setNarrativeData }: CustomizePopupProps) => {
    const [filters, setFilters] = useState(['summarize']);
    const [isLoading, setIsLoading] = useState(false);
    const [isTouched, setIsTouched] = useState(false);
    const [narrative, setNarrative] = useState(narrativeData)
    const { openNotification } = useContext(NotificationContext);
    const user = useSelector((store: RootState) => store.user.user);

    useEffect(() => {
        setNarrative(narrativeData);
    }, [narrativeData])

    const text = convert(narrative || '')

    const onGenerate = async () => {
        const customContext = filters[0];
        setIsLoading(true);
        const data = await fetchCustomData(convert(narrativeData), customContext, user)
        setNarrative(customContext === 'headline_custom' ? data.join('\n') : data)
        setIsTouched(true)
        setIsLoading(false);
    }

    const onSubmit = () => {
        setNarrativeData(narrative)
        onClose();
    }

    const copyText = async () => {
        await navigator.clipboard.writeText(text);
        openNotification('success', 'Copied!', '', 'bottomRight')
    }

    if (!isOpen) return null;
    return (
        <div id="customize-popup" tabIndex={-1} style={{ zIndex: 100000 }} className="flex items-center justify-center bg-[#82838580] bottom-0 fixed left-0 overflow-x-hidden overflow-y-auto right-0 top-0 w-full z-50 h-modal md:h-full md:inset-0">
            <div className="w-full relative accent-block mx-4 px-10 py-6 max-w-[1012px]">
                <button className="absolute p-4 end-2 top-2" id="close-customize-popup" type="button" onClick={onClose}>
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.7517 1L1.75171 11M1.75171 1L11.7517 11" stroke="#2B3C6B" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </button>
                <Spin spinning={isLoading}>
                    <div className="flex items-center flex-col">
                        <div className="font-semibold text-[#1D2E54] text-[32px] leading-[120%] mb-10">Customize Narrative</div>
                        <div className="w-full border-b border-b-[#E6E6E7] pb-5">
                            <div className="flex items-center justify-between gap-2 mb-3">
                                <div className="flex items-center gap-3">
                                    <Select dropdownStyle={{ zIndex: 100001 }} maxTagCount="responsive" size="large" style={{ width: 300 }} value={filters[0]} options={[
                                        {
                                            label: 'Summarize',
                                            value: 'summarize',
                                            key: 'summarize',
                                        },
                                        {
                                            label: 'Bulleted List',
                                            value: 'bulletedList',
                                            key: 'bulletedList',
                                        },
                                        {
                                            label: 'Email format',
                                            value: 'emailWriter',
                                            key: 'emailWriter',
                                        },
                                        {
                                            label: 'Headline',
                                            value: 'headline_custom',
                                            key: 'headline_custom',
                                        },
                                    ]}
                                        onChange={(value) => setFilters([value])} />
                                    <button onClick={onGenerate} className="flex items-center text-[16px] leading-[150%] font-semibold bg-[#F3F2FF] duration-300 ease-in-out h-[44px] hover:bg-[#D5D6FD] hover:text-[#1D2E54] justify-center min-w-[136px] p-[10px] rounded-xl text-[#2B3C6B]" type="button">
                                        <svg className="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.66699 8.33333C1.66699 8.33333 3.33781 6.05685 4.69519 4.69854C6.05257 3.34022 7.92832 2.5 10.0003 2.5C14.1425 2.5 17.5003 5.85786 17.5003 10C17.5003 14.1421 14.1425 17.5 10.0003 17.5C6.58108 17.5 3.69625 15.2119 2.79346 12.0833M1.66699 8.33333V3.33333M1.66699 8.33333H6.66699" stroke="#2B3C6B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        Generate
                                    </button>
                                </div>
                                <button className="flex items-center justify-center h-10 w-10" type="button" id="customize-copy" onClick={copyText}>
                                    <svg className="hover:stroke-[#1D2E54] stroke-[#445082]" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.66699 12.4998C3.89042 12.4998 3.50214 12.4998 3.19585 12.373C2.78747 12.2038 2.46302 11.8794 2.29386 11.471C2.16699 11.1647 2.16699 10.7764 2.16699 9.99984V4.33317C2.16699 3.39975 2.16699 2.93304 2.34865 2.57652C2.50844 2.26292 2.7634 2.00795 3.07701 1.84816C3.43353 1.6665 3.90024 1.6665 4.83366 1.6665H10.5003C11.2769 1.6665 11.6652 1.6665 11.9715 1.79337C12.3798 1.96253 12.7043 2.28698 12.8735 2.69536C13.0003 3.00165 13.0003 3.38993 13.0003 4.1665M10.667 18.3332H16.167C17.1004 18.3332 17.5671 18.3332 17.9236 18.1515C18.2372 17.9917 18.4922 17.7368 18.652 17.4232C18.8337 17.0666 18.8337 16.5999 18.8337 15.6665V10.1665C18.8337 9.23308 18.8337 8.76637 18.652 8.40985C18.4922 8.09625 18.2372 7.84128 17.9236 7.68149C17.5671 7.49984 17.1004 7.49984 16.167 7.49984H10.667C9.73357 7.49984 9.26686 7.49984 8.91034 7.68149C8.59674 7.84128 8.34177 8.09625 8.18198 8.40985C8.00033 8.76637 8.00033 9.23308 8.00033 10.1665V15.6665C8.00033 16.5999 8.00033 17.0666 8.18198 17.4232C8.34177 17.7368 8.59674 17.9917 8.91034 18.1515C9.26686 18.3332 9.73357 18.3332 10.667 18.3332Z" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>
                                </button>
                            </div>

                            <div className="w-full max-h-[500px] overflow-y-auto"
                                style={{
                                    whiteSpace: !narrative?.includes('<html>')  ? 'pre-wrap' : '',
                                }}
                                dangerouslySetInnerHTML={{ __html: narrative }}
                            >
                                {/* {narrative} */}
                            </div>

                        </div>
                        <div className="flex w-full gap-3 justify-end pt-5">
                            {/* <button onClick={onClose} id="customize-popup-cancel" className="rounded-xl border text-[16px] border-[#CACBCB] duration-300 ease-in-out font-semibold hover:bg-[#F3F2FF] hover:border-[#AAABAC] py-[8px] text-[#2C2E30] w-[120px] h-[48px] leading-[150%]" type="button">
                                Cancel
                            </button> */}
                            <button id="customize-popup-submit" onClick={onClose} className="rounded-xl text-[16px] duration-300 ease-in-out font-semibold w-[120px] py-[8px] h-[48px] leading-[150%] bg-[#445082] hover:bg-[#2b3c6b] text-[#ffffff]" type="button">
                                Okay
                            </button>
                        </div>
                    </div>
                </Spin>
            </div>
        </div>
    )
}