import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  report: {
    config: {
      settings: {},
      schema: {},
      objectiveText: '',
    },
  },
  result: {

  },
  report_name: '',
  resultTime: 0,
  reports: [],
};

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setReportConfig(state, action) {
        state.report.config = action.payload;
      },
      setReportSchema(state, action) {
        state.report.config.schema = action.payload;
      },
      setReportSettings(state, action) {
        state.report.config.settings = action.payload;
      },
    setReports(state, action) {
      state.reports = action.payload;
    },
    setReport(state, action) {
      state.report = action.payload;
    },
    setResult(state, action) {
      state.result = action.payload;
    },
    setResultTime(state, action) {
      state.resultTime = action.payload;
    },
    setReportName(state, action) {
      state.report_name = action.payload;
    },
    setReportObjective(state, action) {
      state.report.config.objectiveText = action.payload;
    },
  },
});

export const { setReports, setReportConfig, setReportObjective, setResult, setReportSchema, setResultTime, setReportSettings, setReport, setReportName } = reportsSlice.actions;

export default reportsSlice.reducer;
