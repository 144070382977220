import { EyeCloseIcon } from "../../../components/UI/svgIcons";
import { EyeOpenIcon } from "../../../components/UI/svgIcons";
import { Input } from "../../../components/inputs/input";
import { useEffect, useState } from "react";

interface PasswordStepProps {
  newPassword: string;
  setNewPassword: (password: string) => void;
  confirmPasswordReset: () => void;
}

export const PasswordStep = ({
  newPassword,
  setNewPassword,
  confirmPasswordReset,
}: PasswordStepProps) => {
  const passwordRules = [
    { rule: "Password must contain at least 8 characters", regex: /.{8,}/ },
    { rule: "Password must contain an upper case letter", regex: /[A-Z]/ },
    { rule: "Password must contain a lower case letter", regex: /[a-z]/ },
    { rule: "Password must contain a number", regex: /\d/ },
    {
      rule: "Password must contain a special character or a space",
      regex: /[!@#$%^&* ]/,
    },
    {
      rule: "Password must not contain a leading or trailing space",
      regex: /^(?! ).*(?<! )$/,
    },
  ];

  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [validationResults, setValidationResults] = useState<boolean[]>([]);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (newPassword) {
      const results = passwordRules.map((ruleObj) =>
        ruleObj.regex.test(newPassword)
      );
      setValidationResults(results);
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [newPassword]);

  const handleNext = () => {
    if (newPassword === confirmPassword && newPassword.length >= 6) {
      confirmPasswordReset();
    } else {
      setError(
        newPassword.length < 6
          ? "Password must be at least 6 characters long."
          : "Passwords do not match."
      );
    }
  };

  return (
    <>
      <div className="flex items-center relative justify-between mb-10">
        {/* <!-- Row decor --> */}
        <div className="absolute bg-[#E6E6E7] h-[1px] top-[15px] w-full z-0"></div>
        {/* <!-- Items -->  */}
        <div className="flex items-center relative bg-[#ffffff] flex-col overflow-hidden z-[1] px-[25px]">
          <div className="flex items-center justify-center font-medium h-[32px] leading-[140%] mb-2 rounded-full text-[14px] w-[32px] bg-[#F3F2FF] text-[#1D2E54]">
            <svg
              width="16"
              height="11"
              viewBox="0 0 16 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.2559 0.410826C15.5814 0.736263 15.5814 1.2639 15.2559 1.58934L6.08926 10.756C5.76382 11.0814 5.23618 11.0814 4.91074 10.756L0.744078 6.58934C0.418641 6.2639 0.418641 5.73626 0.744078 5.41083C1.06951 5.08539 1.59715 5.08539 1.92259 5.41083L5.5 8.98824L14.0774 0.410826C14.4028 0.0853888 14.9305 0.0853888 15.2559 0.410826Z"
                fill="#445082"
              />
            </svg>
          </div>
          <div className="font-medium text-[#1D2E54] text-[12px]">
            Enter Details
          </div>
        </div>
        <div className="flex items-center relative bg-[#ffffff] flex-col overflow-hidden z-[1]">
          <div className="flex items-center justify-center font-medium h-[32px] leading-[140%] mb-2 rounded-full text-[14px] w-[32px] bg-[#F3F2FF] text-[#1D2E54]">
            <svg
              width="16"
              height="11"
              viewBox="0 0 16 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.2559 0.410826C15.5814 0.736263 15.5814 1.2639 15.2559 1.58934L6.08926 10.756C5.76382 11.0814 5.23618 11.0814 4.91074 10.756L0.744078 6.58934C0.418641 6.2639 0.418641 5.73626 0.744078 5.41083C1.06951 5.08539 1.59715 5.08539 1.92259 5.41083L5.5 8.98824L14.0774 0.410826C14.4028 0.0853888 14.9305 0.0853888 15.2559 0.410826Z"
                fill="#445082"
              />
            </svg>
          </div>
          <div className="font-medium text-[#1D2E54] text-[12px]">
            Verify Code
          </div>
        </div>
        <div className="flex items-center relative bg-[#ffffff] flex-col overflow-hidden z-[1] px-[25px]">
          <div className="flex items-center justify-center font-medium h-[32px] leading-[140%] mb-2 rounded-full text-[14px] w-[32px] bg-[#445082] text-[#F4F6F8]">
            3
          </div>
          <div className="font-medium text-[#1D2E54] text-[12px]">
            Change Password
          </div>
        </div>
      </div>
      <div className="mb-6">
        <div className="relative mb-[20px]">
          <Input
            label="New Password"
            htmlFor="new-password"
            name="new-password"
            id="new-password"
            type={showPassword ? "text" : "password"}
            placeholder="Enter New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <div
            className="cursor-pointer"
            id="show-password"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <EyeCloseIcon iconClass="absolute end-[12px] select-none top-[34px] " />
            ) : (
              <EyeOpenIcon iconClass="absolute end-[12px] select-none top-[34px] " />
            )}
          </div>
        </div>
        <div className="relative mb-[32px]">
          <Input
            label="Confirm New Password"
            htmlFor="confirm-new-password"
            name="confirm-new-password"
            id="confirm-new-password"
            type={showPassword ? "text" : "password"}
            placeholder="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <div
            className="cursor-pointer"
            id="show-password"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <EyeCloseIcon iconClass="absolute end-[12px] select-none top-[34px] " />
            ) : (
              <EyeOpenIcon iconClass="absolute end-[12px] select-none top-[34px] " />
            )}
          </div>
        </div>
        {error && <p className="text-red-500 text-xs">{error}</p>}
        {isActive && (
          <ul className="text-xs mb-[24px]">
            {passwordRules.map((ruleObj, index) => (
              <li
                key={index}
                style={{
                  color: validationResults[index] ? "green" : "red",
                }}
              >
                {validationResults[index]
                  ? `✓ ${ruleObj.rule}`
                  : `✖ ${ruleObj.rule}`}
              </li>
            ))}
          </ul>
        )}
        <button
          className="w-full accent-btn p-3"
          type="button"
          onClick={handleNext}
        >
          Change
        </button>
      </div>
    </>
  );
};
