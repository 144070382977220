// @ts-nocheck
import { Button } from "antd";

export const ReportGoalsTab = ({
  objectiveText,
  setObjectiveText,
}) => {
  return (
    <div className="w-full border-[#E6E6E7] accent-block border max-w-[1310px]">
      <div className="p-6">
        <div className="h-[612px] w-full">
          <textarea
            value={objectiveText}
            onChange={(e) => setObjectiveText(e.target.value)}
            className="w-full h-full rounded-xl p-4 border border-[#E6E6E7]"
            placeholder="Describe your analysis goals. The more specific you are, the better DataScoop can tailor your analysis."
          />
        </div>
      </div>
    </div>
  )
}