import { ArrowLeftIcon } from "../UI/svgIcons";

interface ButtonProps {
  prevStep: () => void;
}

export const BackStepButton = ({ prevStep }: ButtonProps) => {
  const handlePrev = () => {
    prevStep();
  };

  return (
    <button
      className="flex items-center relative -left-[10px] font-semibold leading-[140%] text-[#2B3C6B] text-[14px]"
      type="button"
      onClick={handlePrev}
    >
      <ArrowLeftIcon iconClass="mr-3" />
      Back
    </button>
  );
};
