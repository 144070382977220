import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { LogoIcon } from "../UI/svgIcons";

interface FormHeaderProps {
    title: string;
    description?: string | ReactNode;
    showLogo?: boolean;
}

export const FormHeader = ({title, description, showLogo}: FormHeaderProps) => {
  return (
    <div className="flex items-center flex-col mb-6">
      {showLogo && (
        <Link to="/">
          <LogoIcon iconClass="mb-6 cursor-pointer" />
        </Link>
      )}
      <h1 className="accent-title mb-3 text-center">{title}</h1>
      {description && <div className="text-[#45484C] text-[16px]">{description}</div>}
    </div>
  );
};