// @ts-nocheck
import { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { setUser, updateUser } from '../../store/userSlice';
import { Button, Form, Image, Input, Spin, Upload } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { PlusOutlined } from '@ant-design/icons';
import { AuthContext } from '../../utils/authContext';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getFullApiLink } from './../../index';

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const AccountSettings = () => {
  const user = useSelector((store: RootState) => store.user.user);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [fileList, setFileList] = useState([])
  const dispatch = useDispatch();
  const { getUserData } = useContext(AuthContext);
  const navigate = useNavigate();
  const [form] = useForm();

  const handleSubmit = async () => {
    console.log(form.getFieldsValue())
    setIsLoading(true)

    const {
      // photo,
      first_name,
      last_name,
      email,
    } = form.getFieldsValue();
    const body = {
      // photo: photo || "",
      first_name,
      last_name,
      email,
    }
    try {
      // @ts-ignore
      const res = await axios(`/api/v1/users/${user.user_id}`, {
        method: 'PUT',
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(body),
      })

      await getUserData(user.email)
      navigate('/home');
    } catch (err) {
      console.log(err, err.statusCode, err.status)
    }
    setIsLoading(false)
  };

  const resetForm = () => {
    form.setFieldsValue(user)
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || (file.preview));
    setPreviewOpen(true);
  };

  const handleChange = ({ fileList: newFileList }) => {
    console.log(newFileList)
    setFileList(newFileList);
    if (newFileList?.length && newFileList[0] && newFileList[0]?.response?.photo) {
      dispatch(setUser({ ...user, photo: newFileList[0].response.photo }))
    }
  }
  const removePhoto = async () => {
    setIsLoading(true)
    try {
      // @ts-ignore
      const res = await axios(`/api/v1/users/delete_photo/${user.user_id}`, {
        method: 'DELETE',
        headers: {
          "Content-Type": "application/json",
        },
      })
      dispatch(setUser({ ...user, photo: '' }))
    } catch (err) {
      console.log(err, err.statusCode, err.status)
    }
    setIsLoading(false)
  }

  const defaultFileList = user.photo ? [
    {
      uid: '1',
      name: 'current.png',
      status: 'done',
      url: getFullApiLink(user.photo),
    }
  ] : [];

  useEffect(() => {
    if (user.photo) {
      setFileList(defaultFileList)
    }
  }, [])

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const sharedInputStyle = {
    maxWidth: 512
  };



  console.log(defaultFileList)

  return (
    <Spin spinning={isLoading}>
      <main className="main bg-white">
        <section className="pb-[48px]">
          <div className="container-custom">
            <NavLink to="/home" className="flex items-center mb-3 pt-[32px]">
              <svg className="mr-2" width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M24.2561 16.4108C24.5815 16.7363 24.5815 17.2639 24.2561 17.5893L19.8453 22.0001L24.2561 26.4108C24.5815 26.7363 24.5815 27.2639 24.2561 27.5893C23.9306 27.9148 23.403 27.9148 23.0776 27.5893L18.0776 22.5893C17.7521 22.2639 17.7521 21.7363 18.0776 21.4108L23.0776 16.4108C23.403 16.0854 23.9306 16.0854 24.2561 16.4108Z" fill="#2C2E30"></path>
              </svg>
              <h1 className="font-semibold text-[32px] text-[#1D2E54]">Account Settings</h1>
            </NavLink>
            <div className="account-settings-tab w-full px-6 py-3">
              <Form
                form={form}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                style={{}}
                initialValues={{ ...user }}
                onFinish={handleSubmit}
                colon={false}
              >
                <Form.Item
                  label="Photo"
                  tooltip="This will be displayed on your profile."
                >
                  <Upload
                    action={getFullApiLink(`/api/v1/users/upload_photo/${user.user_id}`)}
                    listType="picture-circle"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                    onRemove={() => removePhoto()}
                  >
                    {fileList.length >= 1 ? null : uploadButton}
                  </Upload>
                  {previewImage && (
                    <Image
                      wrapperStyle={{ display: 'none' }}
                      preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                        onChange: () => { console.log('change') }
                      }}
                      src={previewImage}
                    />
                  )}
                </Form.Item>
                <div className="border-b-[1px] h-[1px] mb-6"></div>
                <Form.Item
                  label="First Name"
                  name="first_name"
                  rules={[{ required: true, message: 'Please input your first name!' }]}
                >
                  <Input placeholder="First Name" style={sharedInputStyle} />
                </Form.Item>

                <Form.Item
                  label="Last Name"
                  name="last_name"
                  rules={[{ required: true, message: 'Please input your last name!' }]}
                >
                  <Input placeholder="Last Name" style={sharedInputStyle} />
                </Form.Item>
                <div className="border-b-[1px] h-[1px] mb-6"></div>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[{ required: true, message: 'Please input your email!' }, { type: 'email', message: 'Please input valid email!' }]}
                >
                  <Input disabled placeholder="Your Email" style={sharedInputStyle} />
                </Form.Item>
                <div className="border-b-[1px] h-[1px] mb-6"></div>
                <Form.Item
                  label="Organization Name"
                  name={["organization", "name"]}
                  style={{ position: 'relative' }}
                // rules={[{ required: true, message: 'Please input your org name!' }]}
                >
                  <Input disabled style={sharedInputStyle} />
                </Form.Item>
                <div className="border-b-[1px] h-[1px] mb-6"></div>
                <Form.Item
                  label="Subscription Plan"
                  name="subscription_plan"
                  style={{ position: 'relative' }}
                // rules={[{ required: true, message: 'Please input your org name!' }]}
                >
                  <Form.Item
                    name="subscription_plan"
                    style={{ height: 44, marginBottom: 0, }}
                  >
                    <Input disabled style={sharedInputStyle} />
                  </Form.Item>
                  <div style={{
                    position: 'absolute',
                    top: 90,
                    right: 0,
                    // marginTop: -40,
                    left: 342,
                    gap: 8,
                    display: 'flex',
                  }}>
                    <Button size="large" onClick={() => {
                      resetForm();
                      navigate('/home')
                    }}>
                      Cancel
                    </Button>
                    <Button type="primary" size="large" htmlType="submit">
                      Save
                    </Button>
                  </div>

                </Form.Item>
                <div className="border-b-[1px] h-[1px] mb-6"></div>

                <Form.Item wrapperCol={{ offset: 11, span: 1 }}>

                </Form.Item>
              </Form>
            </div>
          </div>
        </section>
      </main>
    </Spin>
  )
}