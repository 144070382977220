import { OnboardingStepComponent } from "./onboardingStepComponent";
import IllustrationFirst from "../../../assets/images/illustration.png";
import IllustrationSecond from "../../../assets/images/illustration-2.png";
import IllustrationThird from "../../../assets/images/illustration-3.png";
import { FormHeader } from "../../../components/auth-components/formHeader";
import { useContext, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { AuthContext } from "../../../utils/authContext";
import { Header } from "../../../components/header/header";

export const OnboardingPage = () => {
  const [currentPopup, setCurrentPopup] = useState<string | null>(null);
  const { isAuthenticated } = useContext(AuthContext);

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }


  const openPopup = (popupId: string) => {
    setCurrentPopup(popupId);
  };

  const closePopup = () => {
    setCurrentPopup(null);
  };

  return (
    <div className="wrapper">
      <Header />
      <main className="main">
        <section className="py-10">
          <div className="container-custom">
            <div className="">
              <div className="mb-[80px]">
                <FormHeader
                  title="Welcome to DataScoop"
                  description="Discover insights in 3 easy steps"
                  showLogo={false}
                />
              </div>
              <div className="flex items-center gap-[34px] justify-stretch mb-[80px]">
                <OnboardingStepComponent
                  number="1"
                  src={IllustrationFirst}
                  alt="illustration"
                  title="Connect to Data"
                  onClick={() => openPopup("video1")}
                />
                <OnboardingStepComponent
                  number="2"
                  src={IllustrationSecond}
                  alt="illustration"
                  title="Enter your analysis goals"
                  onClick={() => openPopup("video2")}
                />
                <OnboardingStepComponent
                  number="3"
                  src={IllustrationThird}
                  alt="illustration"
                  title="Read DataScoop Insights"
                  onClick={() => openPopup("video3")}
                />
              </div>
              <Link
                to="/home"
                className="accent-btn max-w-[416px] mx-auto p-3"
                type="submit"
              >
                Let's get started
              </Link>
            </div>
          </div>
        </section>
      </main>

      {/* <!-- Video popup --> */}
      <div
        id="video-popup"
        tabIndex={-1}
        className={`flex items-center justify-center bg-[#82838580] bottom-0 fixed h-modal ${
          currentPopup ? "" : "hidden"
        } left-0 md:h-full md:inset-0 overflow-x-hidden overflow-y-auto right-0 top-0 w-full z-50`}
      >
        <div className="w-full accent-block relative max-w-[1012px] mx-4 p-10 pt-[64px]">
          <button
            className="end-2 absolute p-4 top-2"
            id="close-video-popup"
            type="button"
            onClick={closePopup}
          >
            <svg
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.7517 1L1.75171 11M1.75171 1L11.7517 11"
                stroke="#2B3C6B"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          {/* <div className="h-[560px] w-[932px]">
            {currentPopup === "video1" && (
              <iframe
                className="w-full h-full rounded-xl"
                src="https://www.youtube.com/embed/-L8S1lIRg50?si=TSCEfWao-BYLOwh7"
                title="Video 1"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            )}
            {currentPopup === "video2" && (
              <iframe
                className="w-full h-full rounded-xl"
                src="https://www.youtube.com/embed/VIDEO_ID_2"
                title="Video 2"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            )}
            {currentPopup === "video3" && (
              <iframe
                className="w-full h-full rounded-xl"
                src="https://www.youtube.com/embed/VIDEO_ID_3"
                title="Video 3"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            )}
          </div> */}

          <div className="h-[560px] w-[932px]">
            {currentPopup === "video1" && (
              <video
                className="w-full h-full rounded-xl"
                src="/static/public/upload_data.mp4"
                title="Upload Data"
                controls
                autoPlay
                muted
              >
                Your browser does not support the video tag.
              </video>
            )}
            {currentPopup === "video2" && (
              <video
                className="w-full h-full rounded-xl"
                src="/static/public/analysis_goals.mp4"
                title="DS Demo"
                controls
                autoPlay
                muted
              >
                Your browser does not support the video tag.
              </video>
            )}
            {currentPopup === "video3" && (
              <video
                className="w-full h-full rounded-xl"
                src="/static/public/read_insights.mp4"
                title="Read Insights"
                controls
                autoPlay
                muted
              >
                Your browser does not support the video tag.
              </video>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};