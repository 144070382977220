import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserState {
    user: {
      first_name?: string;
      last_name?: string;
      email?: string;
      organization?: string;
      org_id?: number;
    };
  }

const initialState: UserState = {
    user:{}
}

const userSlice = createSlice ({
    name: 'user',
    initialState,
    reducers: {
        setUser(state, action: PayloadAction<UserState['user']>) {
            state.user = action.payload;
        },
        removeUser(state) {
            state.user = {};
        },
        updateUser(state, action: PayloadAction<Partial<UserState['user']>>) {
            if (state.user) {
              state.user = {
                ...state.user,
                ...action.payload
              };
            }
          },
    }
})

export const { setUser, removeUser, updateUser } = userSlice.actions;

export default userSlice.reducer