// @ts-nocheck
import { useSelector } from "react-redux";
import { CloseIcon } from "../../components/UI/svgIcons";
import { RootState } from "../../store";
import { Popconfirm } from "antd";
import { useEffect, useState } from "react";

interface SharePopupProps {
    isOpen: boolean;
    onClose: () => void;
    onSaveClick: () => void;
    onSaveReplaceClick: () => void;
    reportName: string;
    setReportName: (value: string) => void;
}

export const SavedPopup = ({
    isOpen,
    onClose,
    onSaveClick,
    onSaveReplaceClick,
    reportName,
    setReportName,
}: SharePopupProps) => {
    const reports = useSelector((store: RootState) => store.reports.reports)
    const [isNameUsed, setIsNameUsed] = useState(false);
    const [nameString, setNameString] = useState(reportName);

    useEffect(()=> {
        setNameString(reportName)
    }, [reportName])

    const validateAndSave = () => {
        const nameUsed = reports.find(report => report.report_name === nameString);
        setIsNameUsed(nameUsed)
        if(!nameUsed){
            onSaveClick(nameString);
        }
    }


    if (!isOpen) return null;

    const btn = (<button id="save-popup-save" onClick={isNameUsed ? () => { } : () => {
        validateAndSave()
    }}
        className="py-[8px] w-[120px] h-[44px] leading-[140%] rounded-xl text-[#ffffff] text-[16px] font-semibold bg-[#445082] hover:bg-[#2b3c6b] ease-in-out duration-300"
        type="button">
        Save
    </button>
    )

    const saveBtn = isNameUsed ?
        (
            <Popconfirm
                style={{ zIndex: 10001 }}
                title="Name already in use!"
                description="Are you sure that you want to replace saved report?"
                onConfirm={() => onSaveReplaceClick(isNameUsed, nameString)}
                onCancel={() => setIsNameUsed(false)}
                okText="Replace"
                cancelText="Cancel"
                open={isNameUsed}
            >
                {btn}
            </Popconfirm>
        )
        : btn;

    return (
        <div id="save-popup" tabIndex={-1}
            className="flex justify-center items-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 bottom-0 z-[1000] w-full md:inset-0 h-modal md:h-full bg-[#82838580]">
            <div className="accent-block relative p-10 w-full max-w-[640px] mx-4">
                <button className="absolute top-2 end-2 p-4" id="close-save-popup" type="button" onClick={onClose}>
                    <CloseIcon />
                </button>
                <div className="flex flex-col items-center">
                    <div className="text-[#1D2E54] text-[32px] font-semibold leading-[120%] mb-6">Save the Analysis</div>
                    <div className="w-full">
                        <div className="flex items-end gap-2 pb-6 border-b border-b-[#E6E6E7]">
                            <div className="w-full">
                                <label className="text-[14px] font-medium inline-block text-[#2C2E30] mb-[6px]" htmlFor="report">
                                    Enter Analysis Name
                                </label>
                                <input
                                    value={nameString}
                                    onChange={(e) => {
                                        if (e.target.value && typeof e.target.value === 'string' && e.target.value.length > 30) {
                                            alert('Please name the analysis within 30 characters')
                                        } else {
                                            setNameString(e.target.value)
                                            setIsNameUsed(false)
                                        }
                                    }}
                                    className="border border-[#AAABAC] rounded-xl px-[14px] py-[9px] w-[100%]" type="text" name="report"
                                    id="report" placeholder="Enter report name" />
                            </div>
                        </div>
                        <div className="pt-6 flex justify-end items-center gap-3">
                            <button id="save-popup-cancel"
                                className="py-[8px] w-[120px] h-[44px] leading-[140%] border border-[#CACBCB] rounded-xl text-[#2C2E30] text-[16px] font-semibold hover:bg-[#F3F2FF] hover:border-[#AAABAC] ease-in-out duration-300"
                                type="button"
                                onClick={onClose}>
                                Cancel
                            </button>
                            {saveBtn}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}