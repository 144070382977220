import { Button, Form, Input, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useContext, useState } from 'react';
import { NotificationContext } from '../../App';

export const FormSection = () => {
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { openNotification } = useContext(NotificationContext);

  const handleSubmit = async () => {
    console.log(form.getFieldsValue());
    setIsLoading(true);
  
    const {
      first_name,
      last_name,
      email,
      organization,
      text,
    } = form.getFieldsValue();
  
    const body = new FormData();
    body.append('first_name', first_name);
    body.append('last_name', last_name);
    body.append('email', email);
    body.append('organization', organization);
    body.append('text', text);
    body.append('access_key', 'fe8d50bd-e27e-40b5-be7b-36b29d95d10f');
  
    try {
      // @ts-ignore
      const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: body
      });
      openNotification('success', 'Sent!', '');
      
      // Reset the form after successful submission
      setTimeout(() => {
        form.resetFields();
      }, 500); // 500ms delay, adjust as needed
  
    } catch (err) {
      openNotification('error', 'Error', '');
    }
    setIsLoading(false);
  };

  return (
    <Spin spinning={isLoading}>
      <section id="get-in-touch" className="lg:py-[48px] py-[36px] bg-[#F3F2FF]">
        <div className="container-custom">
          <div className="text-center mb-[32px] lg:mb-[40px]">
          <h2 className="font-semibold text-center leading-[120%] text-[#1D2E54] text-[24px] lg:text-[32px] lg:mb-4 mb-3">Get in touch</h2>
            <div className="leading-[130%] text-[16px] text-[#445082] lg:text-[20px]">
              If you think that we can help, please leave us a message
            </div>
          </div>
          <div className="max-w-[480px] mb-4 mx-auto">
          <Form layout="vertical" form={form} onFinish={handleSubmit}>
  <div className="flex flex-col lg:flex-row gap-4 mb-4">
    <div className="w-full lg:w-1/2">
      <Form.Item
        label="Enter first name"
        name="first_name"
        rules={[{ required: true, message: 'Please enter your First Name!' }]}
        className="font-medium text-[#2C2E30] text-[14px] mb-2"
      >
        <Input 
          placeholder="First Name" 
          className="w-full border-[#AAABAC] placeholder:text-[#828385] px-4 py-2 rounded-xl" 
        />
      </Form.Item>
    </div>
    <div className="w-full lg:w-1/2">
      <Form.Item
        label="Enter last name"
        name="last_name"
        rules={[{ required: true, message: 'Please enter your Last Name!' }]}
        className="font-medium text-[#2C2E30] text-[14px] mb-2"
      >
        <Input 
          placeholder="Last Name" 
          className="w-full border-[#AAABAC] placeholder:text-[#828385] px-4 py-2 rounded-xl" 
        />
      </Form.Item>
    </div>
  </div>
  <div className="mb-4">
    <Form.Item
      label="Email"
      name="email"
      rules={[
        { required: true, message: 'Please enter your email!' },
        { type: 'email', message: 'Please input a valid email!' }
      ]}
      className="font-medium text-[#2C2E30] text-[14px] mb-2"
    >
      <Input 
        placeholder="Your Email" 
        className="w-full border-[#AAABAC] placeholder:text-[#828385] px-4 py-2 rounded-xl" 
      />
    </Form.Item>
  </div>
  <div className="mb-4">
    <Form.Item
      label="Organization Name"
      name="organization"
      className="font-medium text-[#2C2E30] text-[14px] mb-2"
    >
      <Input 
        placeholder="Organization" 
        className="w-full border-[#AAABAC] placeholder:text-[#828385] px-4 py-2 rounded-xl" 
      />
    </Form.Item>
  </div>
  <div className="mb-4">
    <Form.Item
      label="How can we help?"
      name="text"
      rules={[{ required: true, message: 'Please input how we can help' }]}
      className="font-medium text-[#2C2E30] text-[14px] mb-2"
    >
      <Input.TextArea 
        rows={4} 
        placeholder="Leave us a message..." 
        className="w-full border-[#AAABAC] placeholder:text-[#828385] px-4 py-2 rounded-xl" 
      />
    </Form.Item>
  </div>
  <button 
  type="submit"
  className="w-full flex items-center justify-center font-semibold leading-[140%] duration-300 ease-in-out rounded-xl text-center bg-[#445082] hover:bg-[#2b3c6b] text-[#ffffff] lg:h-[50px] p-3 text-[17px] cursor-pointer"
  >
    Send message
  </button>
</Form>
          </div>
        </div>
      </section>
    </Spin>
  );
};