import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuthenticated } from "../../utils/isAuthenticated";
import { Header } from "../../components/header/header";

export const PrivateRoute = () => {
  const Auth = useAuthenticated();
  const location = useLocation();
  console.log('auth', location, Auth)
  return Auth ? (
    <div className="wrapper">
      <Header />
      <Outlet />
    </div>
  ) : (
    <Navigate to={`/login?requested=${location.pathname}${location.search || ''}`} />
  );
};