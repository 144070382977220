// @ts-nocheck
import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

interface OverviewTableProps {
  basePeriod?: ReactNode;
  comparisonPeriod?: ReactNode;
  baseRows: string | number;
  comparisonRows: string | number;
  aggregationMethods?: string[];
  baselineValue: string | number;
  comparisonValue: string | number;
  percentageDifference: string | number;
  difference: string | number;
  percentageDifferenceRows: string | number;
  differenceRows: string | number;
}
//@ts-ignore
export const OverviewTable = ({ basePeriod, baseRows, comparisonPeriod, comparisonRows, aggregationMethods, baselineValue, comparisonValue, difference, percentageDifference, differenceRows, percentageDifferenceRows, comparisonAggregationValues, baseAggregationValues }: OverviewTableProps) => {
  const result = useSelector((store: RootState) => store.reports.result)
  const differenceNumber = Number(difference);
  const percentageDifferenceNumber = Number(percentageDifference);
  const differenceNumberRows = Number(differenceRows);
  const percentageDifferenceNumberRows = Number(percentageDifferenceRows);

  const filters = useSelector((store: RootState) => store.reports.report.config.settings);
  //@ts-ignore
  const isInverted = filters?.selectedMetricDirection === "decreasing";

  const isPositiveRows = !isInverted ? differenceNumberRows > 0 : differenceNumberRows < 0;
  const isPositive = !isInverted ? differenceNumber > 0 : differenceNumber < 0;

  const arrowUpRows = differenceNumberRows > 0;
  const arrowUp = differenceNumber > 0;



  return (
    <>
      {/* <!-- Table --> */}
      <div className="flex flex-col mb-[32px]">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full">
            <div className="border border-[#E6E6E7] rounded-[20px] overflow-hidden">
              <table className="text-sm bg-[#F9FBFD] dark:text-white font-light min-w-full text-left text-surface">
                <thead className="border-b border-[#E6E6E7] text-[#35383B] bg-[#F4F6F8] leading-[140%] text-[14px]">
                  <tr>
                    <th scope="col" className="px-6 py-4 w-[328px]"></th>
                    <th scope="col" className="font-medium px-6 py-3">
                      Period
                    </th>
                    <th scope="col" className="font-medium px-6 py-3">
                      Rows
                    </th>
                    {aggregationMethods?.map(aggregationMethod => (
                      <th scope="col" className="font-medium px-6 py-3">
                        {result?.[aggregationMethod]?.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b border-[#E6E6E7] text-[#35383B] font-normal leading-[150%] text-[16px]">
                    <td className="p-6 whitespace-nowrap text-[#445082]">
                      Base
                    </td>
                    <td className="p-6 whitespace-nowrap">
                      {basePeriod}
                    </td>
                    <td className="p-6 whitespace-nowrap">
                      <div className="flex items-center gap-3">
                        <div className="">{baseRows}</div>
                        <div className="flex items-center font-medium text-[14px] px-2 py-[2px] rounded-[16px] bg-[#FEF3F2] text-[#B42318] hidden">
                          <svg
                            className="mr-1"
                            width="13"
                            height="12"
                            viewBox="0 0 13 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M6.5 2C6.77614 2 7 2.22386 7 2.5V8.29289L9.64645 5.64645C9.84171 5.45118 10.1583 5.45118 10.3536 5.64645C10.5488 5.84171 10.5488 6.15829 10.3536 6.35355L6.85355 9.85355C6.65829 10.0488 6.34171 10.0488 6.14645 9.85355L2.64645 6.35355C2.45118 6.15829 2.45118 5.84171 2.64645 5.64645C2.84171 5.45118 3.15829 5.45118 3.35355 5.64645L6 8.29289V2.5C6 2.22386 6.22386 2 6.5 2Z"
                              fill="#F04438"
                            />
                          </svg>
                          {differenceRows} ({percentageDifferenceRows}%)
                        </div>
                      </div>
                    </td>
                    {/* @ts-ignore */}
                    {baseAggregationValues.map(value => (
                      <td className="p-6 whitespace-nowrap">
                        <div className="flex items-center gap-3">
                          <div className="">{value.value}</div>
                        </div>
                      </td>
                    ))}
                  </tr>
                  <tr className="border-b border-[#E6E6E7] text-[#35383B] font-normal leading-[150%] text-[16px]">
                    <td className="p-6 whitespace-nowrap text-[#445082]">
                      Comparison
                    </td>
                    <td className="p-6 whitespace-nowrap">
                      {comparisonPeriod}
                    </td>
                    <td className="p-6 whitespace-nowrap">
                      <div className="flex items-center gap-3">
                        <div className="">{comparisonRows}</div>
                        <div
                          className={`flex items-center font-medium text-[14px] px-2 py-[2px] rounded-[16px] ${isPositiveRows ? 'bg-[#ECFDF3] text-[#027A48]' : 'bg-[#FDE7E7] text-[#D92D20]'
                            }`}
                        >
                          <svg
                            className="mr-1"
                            width="13"
                            height="12"
                            viewBox="0 0 13 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d={arrowUpRows ?
                                "M6.14645 2.14645C6.34171 1.95118 6.65829 1.95118 6.85355 2.14645L10.3536 5.64645C10.5488 5.84171 10.5488 6.15829 10.3536 6.35355C10.1583 6.54882 9.84171 6.54882 9.64645 6.35355L7 3.70711V9.5C7 9.77614 6.77614 10 6.5 10C6.22386 10 6 9.77614 6 9.5V3.70711L3.35355 6.35355C3.15829 6.54882 2.84171 6.54882 2.64645 6.35355C2.45118 6.15829 2.45118 5.84171 2.64645 5.64645L6.14645 2.14645Z"
                                :
                                "M6.14645 9.85355C6.34171 10.0488 6.65829 10.0488 6.85355 9.85355L10.3536 6.35355C10.5488 6.15829 10.5488 5.84171 10.3536 5.64645C10.1583 5.45118 9.84171 5.45118 9.64645 5.64645L7 8.29289V2.5C7 2.22386 6.77614 2 6.5 2C6.22386 2 6 2.22386 6 2.5V8.29289L3.35355 5.64645C3.15829 5.45118 2.84171 5.45118 2.64645 5.64645C2.45118 5.84171 2.45118 6.15829 2.64645 6.35355L6.14645 9.85355Z"
                              }
                              fill={isPositiveRows ? "#12B76A" : "#D92D20"}
                            />
                          </svg>
                          {differenceNumberRows.toLocaleString()} ({percentageDifferenceNumberRows}%)
                        </div>
                      </div>
                    </td>
                    {/* @ts-ignore */}
                    {comparisonAggregationValues.map(value => (
                      <td className="p-6 whitespace-nowrap">
                        <div className="flex items-center gap-3">
                          <div className="">{value.value}</div>
                          <div
                            className={`flex items-center font-medium text-[14px] px-2 py-[2px] rounded-[16px] ${isPositive ? 'bg-[#ECFDF3] text-[#027A48]' : 'bg-[#FDE7E7] text-[#D92D20]'
                              }`}
                          >
                            <svg
                              className="mr-1"
                              width="13"
                              height="12"
                              viewBox="0 0 13 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d={arrowUp ?
                                  "M6.14645 2.14645C6.34171 1.95118 6.65829 1.95118 6.85355 2.14645L10.3536 5.64645C10.5488 5.84171 10.5488 6.15829 10.3536 6.35355C10.1583 6.54882 9.84171 6.54882 9.64645 6.35355L7 3.70711V9.5C7 9.77614 6.77614 10 6.5 10C6.22386 10 6 9.77614 6 9.5V3.70711L3.35355 6.35355C3.15829 6.54882 2.84171 6.54882 2.64645 6.35355C2.45118 6.15829 2.45118 5.84171 2.64645 5.64645L6.14645 2.14645Z"
                                  :
                                  "M6.14645 9.85355C6.34171 10.0488 6.65829 10.0488 6.85355 9.85355L10.3536 6.35355C10.5488 6.15829 10.5488 5.84171 10.3536 5.64645C10.1583 5.45118 9.84171 5.45118 9.64645 5.64645L7 8.29289V2.5C7 2.22386 6.77614 2 6.5 2C6.22386 2 6 2.22386 6 2.5V8.29289L3.35355 5.64645C3.15829 5.45118 2.84171 5.45118 2.64645 5.64645C2.45118 5.84171 2.45118 6.15829 2.64645 6.35355L6.14645 9.85355Z"
                                }
                                fill={isPositive ? "#12B76A" : "#D92D20"}
                              />
                            </svg>
                            {value.difference.toLocaleString('en-US')} ({value.percentageDifference}%)
                          </div>
                        </div>
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
