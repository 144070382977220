import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import reportsReducer from "./reportsSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    reports: reportsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      actionCreatorCheck: false,
      immutableCheck: false,
      thunk: true,
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>