import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Input } from "../../../components/inputs/input";
import {
  EyeCloseIcon,
  EyeOpenIcon,
  GoogleIcon,
  LogoIcon,
} from "../../../components/UI/svgIcons";
import { FormHeader } from "../../../components/auth-components/formHeader";
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import { awsConfig } from "../../../services/awsConfig";
import { AuthContext } from "../../../utils/authContext";


export const LoginPage = () => {
  const { handleLogin, fetchSession } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const userPool = new CognitoUserPool({
    UserPoolId: awsConfig.userPoolId,
    ClientId: awsConfig.clientId,
  });




  const logIn = () => {
    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    const userData = {
      Username: email,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        console.log('access token + ' + result.getAccessToken().getJwtToken());
        console.log('id token + ' + result.getIdToken().getJwtToken());
        console.log('refresh token + ' + result.getRefreshToken().getToken());
        // localStorage.setItem('authToken', result.getAccessToken().getJwtToken());
        fetchSession();
        handleLogin();
        localStorage.setItem('tokens', JSON.stringify({
          accessToken: result.getAccessToken().getJwtToken(),
          idToken: result.getIdToken().getJwtToken(),
          refreshToken: result.getRefreshToken().getToken(),
        }))
        navigate("/home");
      },
      onFailure: (err) => {
        console.error(err.message || JSON.stringify(err));
        setErrorMessage("Incorrect username or password. Please try again.");
      },

    });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get('code');

    if (code) {
      handleGoogleCallback(code);
    }
  }, [location]);

  const handleGoogleCallback = async (code: any) => {
    try {
      const response = await fetch(`https://datasc.auth.eu-north-1.amazoncognito.com/oauth2/token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          grant_type: 'authorization_code',
          client_id: awsConfig.clientId,
          code: code,
          redirect_uri: 'http://localhost:4001/login',
        }),
      });

      const data = await response.json();

      if (data.access_token) {
        localStorage.setItem('accessToken', data.access_token);
        localStorage.setItem('idToken', data.id_token);
        handleLogin();
        navigate("/home");
      } else {
        throw new Error('Failed to get tokens');
      }
    } catch (error) {
      console.error('Error during Google authentication:', error);
      setErrorMessage("Failed to authenticate with Google. Please try again.");
    }
  };


  const signInWithGoogle = () => {
    const cognitoDomain = 'https://datasc.auth.eu-north-1.amazoncognito.com';
    const clientId = awsConfig.clientId;
    const redirectUri = encodeURIComponent('http://localhost:4001/login');

    const url = `${cognitoDomain}/oauth2/authorize?` +
      `client_id=${clientId}&` +
      `response_type=code&` +
      `scope=email+openid+profile&` +
      `redirect_uri=${redirectUri}&` +
      `identity_provider=Google`;

    window.location.href = url;
  };

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    logIn();
  };

  return (
    <div className="wrapper">
      <main className="flex-wrapper main">
        <section className="">
          <div className="flex items-center justify-center container-custom">
            <div style={{
              width: 'calc(100vw - 32px)'
            }} className="accent-block my-4 px-10 py-6 max-w-[560px]">
              <Link to="/">
                <LogoIcon iconClass="h-16 w-16 mb-6 mx-auto cursor-pointer" />
              </Link>
              <FormHeader title="Welcome to DataScoop" description="Please enter your details." />
              <form action="" onSubmit={handleSubmit} className="mb-4">
                <div className="relative mb-[20px]">
                  <Input
                    label="Email"
                    htmlFor="email"
                    value={email}
                    name="email"
                    id="email"
                    type="email"
                    placeholder="Enter your Email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="relative mb-[20px]">
                  <Input
                    label="Password"
                    htmlFor="password"
                    value={password}
                    name="password"
                    id="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter your Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div
                    className="cursor-pointer"
                    id="show-password"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <EyeCloseIcon iconClass="absolute end-[12px] select-none top-[34px] " />
                    ) : (
                      <EyeOpenIcon iconClass="absolute end-[12px] select-none top-[34px] " />
                    )}
                  </div>
                </div>
                {errorMessage && (
                  <div className="text-sm text-center text-red-500 mb-2">{errorMessage}</div>
                )}

                <div className="flex items-center justify-between mb-[24px]">
                  <a
                    href="reset-password"
                    className="text-[14px] font-semibold ml-2 text-[#2B3C6B]"
                  >
                    Forgot password?
                  </a>
                </div>
                <button onClick={handleSubmit} className="w-full accent-btn p-3" type="submit">
                  Sign In
                </button>
              </form>
              <div className="">
                {/* <div className="flex justify-center mb-4 relative">
                  <div className="w-full absolute bg-[#E6E6E7] h-[1px] top-[50%]"></div>
                  <div className="text-[14px] text-[#35383B] text-center bg-[#ffffff] py-[7px] relative w-[48px] z-[1]">
                    or
                  </div>
                </div>
                <div className="flex flex-col gap-3 mb-[32px]">
                  <button
                    className="flex items-center justify-center bg-[#F4F6F8] border border-[#AAABAC] duration-300 ease-in-out font-semibold h-[44px] hover:bg-[#D5D6FD] p-[10px] rounded-xl text-[#2B3C6B] text-[16] w-full"
                    type="button"
                    onClick={signInWithGoogle}
                  >
                    <GoogleIcon iconClass="mr-4" />
                    Sign in with Google
                  </button>
                  <a
                    href="sign-up.html"
                    className="border border-[#AAABAC] rounded-xl bg-[#F4F6F8] duration-300 ease-in-out font-semibold h-[44px] hover:bg-[#D5D6FD] p-[10px] text-[#2B3C6B] text-[16] w-full leading-[140%] text-center"
                    type="button"
                  >
                    Sign in with SSO
                  </a>
                </div> */}
                <div className="text-[14px] text-[#35383B] text-center">
                  Don't have an account?
                  <a
                    href="register"
                    className="underline font-semibold text-[#2B3C6B] underline-offset-4 ml-2"
                  >
                    Sign up
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};