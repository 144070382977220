import { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  ArrowAroundIcon,
  PlayIcon,
  ReportSearchIcon,
  ReportSetupIcon,
  SubmitIcon,
  ReportsObjectiveIcon,
  NextIcon
} from "../../components/UI/svgIcons";
import { DataPreviewTab } from "./dataPreviewTab";
import { LearnTab } from "./learnTab";
import { ReportSetupTab } from "./reportSetupTab";
import { ReportGoalsTab } from "./reportGoalsTab";
import { RootState } from "./../../store";
import { setReportConfig, setResult, setResultTime } from "../../store/reportsSlice";
import axios from "axios";
import { Button, Spin } from "antd";
import dayjs from "dayjs";
import { useForm } from "antd/es/form/Form";
import { useFilterForm } from "./../../modules/discover-segments/filter";

const sharedStyles = {
  width: '100%',
  justifyContent: 'center',
};

export enum ReportTabEnum {
  reportGoals = "reportGoals",
  setupReport = "setupReport",
  dataPreview = "dataPreview",
  // learnReport = "learnReport",
}

export const ReportSetupPage = () => {
  const [activeTab, setActiveTab] = useState(ReportTabEnum.dataPreview);
  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams();

  const openedReportKey = searchParams.get("reportId")

  const {
    updatePayload,
    form,
    resetForm,
    updateCounter,
    sendData,
    resetCounter,
    isLoading,
    payload,
    objectiveText,
    setObjectiveText,
  } = useFilterForm({ onSent: () => navigate(`/discover-segments${openedReportKey ? `?reportId=${openedReportKey}` : '' }`) })

  const config = useSelector((store: RootState) => store.reports.report.config)
  const reportName = useSelector((store: RootState) => store.reports.report_name)
  const { schema } = config;

  const handleTabClick = (tab: ReportTabEnum) => {
    setActiveTab(tab);
  };

  const handleNextClick = () => {
    if (activeTab === ReportTabEnum.dataPreview) {
      setActiveTab(ReportTabEnum.reportGoals);
    } else if (activeTab === ReportTabEnum.reportGoals) {
      setActiveTab(ReportTabEnum.setupReport);
    }
  };


  return (
    <main className="relative main">
      <Spin spinning={isLoading}>
        <section className="mb-10 pt-10">
          <div className="flex items-center flex-col container-custom">
          <div className="flex items-center justify-between w-full mb-6">
            <h1 className="accent-title-dark">
              Analysis Setup
            </h1>
            <div className="flex gap-4">
              <Link
                to="/home"
                className="flex items-center font-semibold justify-center leading-[140%] text-[14px] duration-300 ease-in-out h-[44px] py-[8px] rounded-xl w-[144px] border border-[#CACBCB] hover:bg-[#F3F2FF] hover:border-[#AAABAC] text-[#2C2E30]"
              >
                <ArrowAroundIcon iconClass="mr-2" />
                Start Over
              </Link>
              {activeTab !== ReportTabEnum.setupReport ? (
                <Button
                  onClick={handleNextClick}
                  type="primary"
                  className="flex items-center font-semibold justify-center leading-[140%] text-[14px] duration-300 ease-in-out h-[44px] py-[8px] rounded-xl w-[144px] bg-[#445082] hover:bg-[#2b3c6b] text-[#ffffff]"
                >
                  <NextIcon iconClass="mr-2" />
                  Next
                </Button>
              ) : (
                <Button
                  onClick={() => sendData()}
                  disabled={payload?.isError}
                  type="primary"
                  className="flex items-center font-semibold justify-center leading-[140%] text-[14px] duration-300 ease-in-out h-[44px] py-[8px] rounded-xl w-[144px] bg-[#445082] hover:bg-[#2b3c6b] text-[#ffffff]"
                >
                  <SubmitIcon iconClass="mr-2" />
                  Submit
                </Button>
              )}
            </div>
          </div>

            <div className="flex items-center justify-between w-full mb-6 bg-[#f0f2f5] gap-[21px] max-w-[1088px] px-3 py-2 rounded-[24px]">
              <button
                onClick={() => handleTabClick(ReportTabEnum.dataPreview)}
                className={`flex items-center font-medium text-[14px] duration-300 ease-in-out justify-center leading-[120%] max-w-[340px] p-[10px] rounded-[10px] w-full ${activeTab === ReportTabEnum.dataPreview ? 'bg-[#ffffff] shadow-md text-[#1D2E54]' : 'hover:bg-[#ffffff] hover:shadow-md hover:text-[#1D2E54] text-[#828385]'}`}
              >
                <ReportSearchIcon iconClass="mr-2" />
                Data Preview
              </button>
              <button
                onClick={() => handleTabClick(ReportTabEnum.reportGoals)}
                className={`flex items-center font-medium text-[14px] duration-300 ease-in-out justify-center leading-[120%] max-w-[340px] p-[10px] rounded-[10px] w-full ${activeTab === ReportTabEnum.reportGoals ? 'bg-[#ffffff] shadow-md text-[#1D2E54]' : 'hover:bg-[#ffffff] hover:shadow-md hover:text-[#1D2E54] text-[#828385]'}`}
              >
                <ReportsObjectiveIcon iconClass="mr-2" />
                Objective
              </button>
              <button
                onClick={() => handleTabClick(ReportTabEnum.setupReport)}
                className={`flex items-center font-medium text-[14px] duration-300 ease-in-out justify-center leading-[120%] max-w-[340px] p-[10px] rounded-[10px] w-full ${activeTab === ReportTabEnum.setupReport ? 'bg-[#ffffff] shadow-md text-[#1D2E54]' : 'hover:bg-[#ffffff] hover:shadow-md hover:text-[#1D2E54] text-[#828385]'}`}
              >
                <ReportSetupIcon iconClass="mr-2" />
                Configuration
              </button>
              {/* <button
                onClick={() => handleTabClick(ReportTabEnum.learnReport)}
                className={`flex items-center font-medium text-[14px] duration-300 ease-in-out justify-center leading-[120%] max-w-[340px] p-[10px] rounded-[10px] w-full ${activeTab === ReportTabEnum.learnReport ? 'bg-[#ffffff] shadow-md text-[#1D2E54]' : 'hover:bg-[#ffffff] hover:shadow-md hover:text-[#1D2E54] text-[#828385]'}`}
              >
                <PlayIcon iconClass="mr-2" />
                Learn
              </button> */}
            </div>
            <div style={{ display: activeTab === ReportTabEnum.reportGoals ? 'flex' : 'none', ...sharedStyles }}>
              <ReportGoalsTab
                objectiveText={objectiveText}
                setObjectiveText={setObjectiveText} />
            </div>
            <div style={{ display: activeTab === ReportTabEnum.setupReport ? 'flex' : 'none', flexWrap: 'wrap', gap: 16, ...sharedStyles }}>
              <ReportSetupTab updatePayload={updatePayload} resetCounter={resetCounter} form={form} updateCounter={updateCounter} />
              <div className="flex gap-4">
                <Link
                  to="/home"
                  className="flex items-center font-semibold justify-center leading-[140%] text-[14px] duration-300 ease-in-out h-[44px] py-[8px] rounded-xl w-[144px] border border-[#CACBCB] hover:bg-[#F3F2FF] hover:border-[#AAABAC] text-[#2C2E30]"

                >
                  <ArrowAroundIcon iconClass="mr-2" />
                  Start Over
                </Link>
                {activeTab !== ReportTabEnum.reportGoals && (
                  <Button
                    onClick={() => sendData()}
                    // @ts-ignore
                    disabled={payload?.isError}
                    type="primary"
                    className="flex items-center font-semibold justify-center leading-[140%] text-[14px] duration-300 ease-in-out h-[44px] py-[8px] rounded-xl w-[144px] bg-[#445082] hover:bg-[#2b3c6b] text-[#ffffff]">
                    <SubmitIcon iconClass="mr-2" />
                    Submit
                  </Button>
                )}
              </div>
            </div>
            <div style={{ display: activeTab === ReportTabEnum.dataPreview ? 'flex' : 'none', ...sharedStyles }}>
              <DataPreviewTab data={schema} />
            </div>
            {/* <div style={{ display: activeTab === ReportTabEnum.learnReport ? 'flex' : 'none', ...sharedStyles }}>
              <LearnTab />
            </div> */}
          </div>
        </section>
      </Spin>
    </main>
  );
};
