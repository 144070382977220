import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store";
import axios, { InternalAxiosRequestConfig } from "axios";
import dayjs from "dayjs";
import * as timezone from 'dayjs/plugin/timezone' 
import * as utc from 'dayjs/plugin/utc' 
import * as minMax from 'dayjs/plugin/minMax';
// import sgMail from '@sendgrid/mail';

// sgMail.setApiKey('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');

const currentOrigin = window.location.origin;
const apiPrefix = '/api/v1';

const apiUrl = 'http://localhost';
const apiPort = '4001';

const isLocalhost = currentOrigin.includes('localhost');

//@ts-ignore
dayjs.extend(utc)
//@ts-ignore
dayjs.extend(timezone)
//@ts-ignore
dayjs.extend(minMax);

dayjs.tz.setDefault("")

axios.interceptors.request.use(function (config: InternalAxiosRequestConfig) {
  console.log(config);
  if (config.url?.includes(apiPrefix)) {
    if(isLocalhost){
      config.baseURL = `${apiUrl}:${apiPort}`
    } else {
      config.baseURL = currentOrigin;
    }
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

export const getFullApiLink = (url: string) => {
  if (url?.includes(apiPrefix)) {
    if(isLocalhost){
      return `${apiUrl}:${apiPort}${url}`;
    } else {
     return url;
    }
  }
  return url;
}


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

//@ts-ignore
window.$store = store;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
