export const FinalStep = () => {
  return (
    <>
      <div className="flex items-center flex-col mb-[32px]">
        <div className="flex items-center justify-center bg-[#F3F2FF] h-[32px] mb-[20px] rounded-full w-[32px]">
          <svg
            width="16"
            height="11"
            viewBox="0 0 16 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15.2559 0.410826C15.5814 0.736263 15.5814 1.2639 15.2559 1.58934L6.08926 10.756C5.76382 11.0814 5.23618 11.0814 4.91074 10.756L0.744078 6.58934C0.418641 6.2639 0.418641 5.73626 0.744078 5.41083C1.06951 5.08539 1.59715 5.08539 1.92259 5.41083L5.5 8.98824L14.0774 0.410826C14.4028 0.0853888 14.9305 0.0853888 15.2559 0.410826Z"
              fill="#445082"
            />
          </svg>
        </div>
        <div className="font-semibold mb-2 text-[#445082] text-[24px]">
          Password changed
        </div>
        <div className="text-[#45484C] text-[14px] text-center">
          Your new password has been set. You can login to your account
        </div>
      </div>
      <div className="mb-6">
        <a href="login" className="accent-btn p-3 w-full" type="button">
          Sign In
        </a>
      </div>
    </>
  );
};
