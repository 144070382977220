import Augmented from 'assets/images/augmented.png';


export const UniqueValueSection = () => {
    return (
        <section className="lg:py-[48px] py-[36px] bg-[#F3F2FF] mb-[48px]"  id="value">
          <div className="container-custom">
            <h2 className="font-semibold text-center leading-[120%] text-[#1D2E54] text-[24px] lg:text-[32px] lg:mb-4 mb-3">Welcome to Augmented Analytics</h2>
            <div className="leading-[150%] text-center text-[16px] text-[#45484C] lg:text-[20px] lg:mb-4 mb-3">that goes beyond your Data Warehouse</div>
            <div className="flex items-center justify-center flex-col gap-[20px] lg:flex-row lg:gap-[32px]">
            
                <img className="w-[50%] max-w-[225px]" src={Augmented} alt="Augmented Analytics" />
            
              <div className=" max-w-[318px]">
              <div className="font-semibold leading-[120%] text-[#1D2E54] text-[24px] mb-3 xl:mb-4 xl:text-[26px]">
              Our unique value </div>
                <div className="leading-[150%] text-[#45484C] text-[14px] xl:text-[16px]">
                DataScoop connects the dots between your business context and relevant real-world factors, delivering a holistic explanation of your KPI fluctuations.
                </div>
                </div>
              
            </div>
          </div>
        </section>
    )
}