// @ts-nocheck

interface AdditionalPopupProps {
    isOpen: boolean;
    onClose: () => void;
}

export const AdditionalPopup = ({ isOpen, onClose, onSubmit, userContextState, setUserContextState }: AdditionalPopupProps) => {
    if (!isOpen) return null;
    return (
        <div id="context-popup" tabIndex={-1} style={{ zIndex: 10001 }} className="flex items-center justify-center bg-[#82838580] bottom-0 fixed left-0 overflow-x-hidden overflow-y-auto right-0 top-0 w-full z-50">
            <div className="w-full relative accent-block mx-4 border border-[#E6E6E7] max-w-[630px] p-6">
                <div className="font-semibold text-[#1D2E54] mb-6 leading-[130%] text-[20px]">Additional Context</div>
                <textarea  value={userContextState} onChange={(e)=> setUserContextState(e.target.value)} className="rounded-xl border border-[#AAABAC] px-[14px] w-[100%] py-[10px] text-[16px] h-[156px] placeholder:text-[#45484C] text-[#45484C]" name="description" placeholder="Add additional context for the narrative above ..."></textarea>
                <div className="flex w-full gap-3 justify-end pt-4">
                    <button onClick={onClose} id="context-popup-cancel" className="rounded-xl border text-[16px] border-[#CACBCB] duration-300 ease-in-out font-semibold hover:bg-[#F3F2FF] hover:border-[#AAABAC] py-[8px] text-[#2C2E30] w-[120px] h-[48px] leading-[150%]" type="button">
                        Cancel
                    </button>
                    <button id="context-popup-submit" onClick={onSubmit} className="rounded-xl text-[16px] duration-300 ease-in-out font-semibold w-[120px] py-[8px] h-[48px] leading-[150%] bg-[#445082] hover:bg-[#2b3c6b] text-[#ffffff]" type="button">
                        Submit
                    </button>
                </div>
            </div>
        </div>
    )
}