import IllustrationFirst from "assets/images/illustration.png";
import IllustrationSecond from "assets/images/illustration-2.png";
import IllustrationThird from "assets/images/illustration-3.png";
//@ts-ignore
// import videoSrc from 'assets/video/Narrative_Demo.mp4';


export const GettingStarted = () => {
  return (
    <section id="solution" className="pt-[32px] lg:pt-0 mb-[0] mt-[0]">
      <div className="container-custom">
        {/* <div className="flex flex-col-reverse xl:flex-row items-center justify-between gap-[32px] xl:gap-[60px] mb-[80px]">
              <video width="600" controls >
                <source src={videoSrc} type="video/mp4" />
                Your browser does not support video.
              </video>
              <div className="xl:max-w-[570px]">
                <div className="font-semibold leading-[120%] text-[#1D2E54] text-[24px] mb-3 xl:mb-4 xl:text-[26px]">
                DataScoop accelerates
                Data Exploration</div>
                <div className="leading-[150%] text-[#45484C] text-[14px] xl:text-[16px]">
                DataScoop leverages the latest advancements in AI to generate the key reasons driving your business growth - it's power comes from its ability to associate KPI changes to external contexts
                </div>
              </div>
            </div> */}

      </div>
      <div className="container-custom">
        <div className="font-semibold leading-[120%] text-[#1D2E54] text-[24px] lg:text-[32px] text-center lg:mb-4 mb-3">Onboarding is really easy</div>
        <div className="leading-[150%] text-center text-[14px] text-[#45484C] lg:text-[18px] lg:mb-[18px] mb-[18px]">We promise to deliver impact in 90 days</div>
        <div className="flex items-center flex-col lg:flex-row gap-[34px] justify-around py-[36px] xl:py-[48px]">
          <div className="flex items-center flex-col lg:max-w-[unset] accent-block border border-[#E6E6E7] max-w-[262px] p-6 relative w-full">
            <div className="flex items-center justify-center font-semibold -left-[20px] -top-[20px] absolute bg-[#445082] border-2 border-[#7F8ACA] h-[48px] leading-[125%] rounded-full text-[#FFFFFF] text-[24px] w-[48px]">
              1
            </div>
            <img className="mb-6" src={IllustrationFirst} alt="illustration." />
            <div className="font-semibold text-[#445082] text-[20px]">Load Data</div>
            <div className="mt-[20px] leading-[120%] text-[#45484C] text-[14px] xl:text-[16px] text-center">
              Import your data in a csv file, or connect to data
            </div>
          </div>
          <div className="flex items-center flex-col lg:max-w-[unset] accent-block border border-[#E6E6E7] max-w-[262px] p-6 relative w-full">
            <div className="flex items-center justify-center font-semibold -left-[20px] -top-[20px] absolute bg-[#445082] border-2 border-[#7F8ACA] h-[48px] leading-[125%] rounded-full text-[#FFFFFF] text-[24px] w-[48px]">
              2
            </div>
            <img className="mb-6" src={IllustrationSecond} alt="illustration." />
            <div className="font-semibold text-[#445082] text-[20px]">Pick a KPI</div>
            <div className="mt-[20px] leading-[150%] text-[#45484C] text-[14px] xl:text-[16px] text-center">
              DataScoop focuses on the metric you want to track
            </div>
          </div>
          <div className="flex items-center flex-col lg:max-w-[unset] accent-block border border-[#E6E6E7] max-w-[262px] p-6 relative w-full">
            <div className="flex items-center justify-center font-semibold -left-[20px] -top-[20px] absolute bg-[#445082] border-2 border-[#7F8ACA] h-[48px] leading-[125%] rounded-full text-[#FFFFFF] text-[24px] w-[48px]">
              3
            </div>
            <img className="mb-6" src={IllustrationThird} alt="illustration." />
            <div className="font-semibold text-[#445082] text-[20px]">Read Insights</div>
            <div className="mt-[20px] leading-[150%] text-[#45484C] text-[14px] xl:text-[16px] text-center">
              Delivered as executive summary to your Inbox
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}