// export const HomeLearnMoreTab = () => {
//   return (
//     <div className="w-full max-w-[864px] accent-block">
//       <h2 className="font-semibold border-b border-b-[#E6E6E7] py-6 text-[#1D2E54] text-[20px] text-center">
//         Learn More about DataScoop
//       </h2>
//       <div className="p-6">
//         <div className="h-[472px] w-[816px]">
//           <iframe
//             className="w-full h-full rounded-xl"
//             src="https://www.youtube.com/embed/-L8S1lIRg50?si=TSCEfWao-BYLOwh7"
//             title="YouTube video player"
//             frameBorder="0"
//             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//             referrerPolicy="strict-origin-when-cross-origin"
//             allowFullScreen
//           ></iframe>
//         </div>
//       </div>
//     </div>
//   );
// };


import React from 'react';

export const HomeLearnMoreTab = () => {
  return (
    <div className="w-full max-w-[864px] accent-block">
      <h2 className="font-semibold border-b border-b-[#E6E6E7] py-6 text-[#1D2E54] text-[20px] text-center">
        Learn More about DataScoop
      </h2>
      <div className="p-6">
        <div className="h-[472px] w-[816px]">
          <video
            className="w-full h-full rounded-xl"
            controls
            autoPlay
            muted
            src="/static/public/DS_Demo.mp4"
          >
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};