import { useState } from "react";
import { EmailStep } from "./emailStep";
import { SecureCodeStep } from "./secureCodeStep";
import { PasswordStep } from "./passwordStep";
import { FinalStep } from "./finalStep";
import { FormHeader } from "../../../components/auth-components/formHeader";
import { BackStepButton } from "../../../components/auth-components/backStepBtn";
import { awsConfig } from "../../../services/awsConfig";
import { CognitoIdentityServiceProvider } from "aws-sdk";
import { CognitoUserPool } from "amazon-cognito-identity-js";

export enum StepsEnum {
  email = "email",
  code = "code",
  newPass = "newPass",
  final = "final",
}

export const ResetPasswordPage = () => {
  const [currentStep, setCurrentStep] = useState(StepsEnum.email);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  // const [confirmationMessage, setConfirmationMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const poolData = {
    UserPoolId: awsConfig.userPoolId,
    ClientId: awsConfig.clientId,
  };
  const userPool = new CognitoUserPool(poolData);

  const headerDescription: { [key in StepsEnum]?: string | JSX.Element } = {
    email: "Please enter your email",
    code: (
      <>
        If the email exists, we will send an email to
        <span className="font-medium text-[#445082]"> {email} </span> with the 6
        digit code
      </>
    ),
    newPass: "Update your new password.",
  };

  const cognitoIdentityServiceProvider = new CognitoIdentityServiceProvider({
    region: awsConfig.region,
  });

  const requestPasswordReset = async (email: string) => {
    console.log("Requesting password reset");
    const params = {
      ClientId: awsConfig.clientId,
      Username: email,
    };

    try {
      await cognitoIdentityServiceProvider.forgotPassword(params).promise();
      setCurrentStep(StepsEnum.code);
    } catch (error) {
      console.error("Error requesting password reset:", error);
      setErrorMessage("Failed to send password reset code. Please try again.");
    }
  };

  const confirmPasswordReset = async (
    email: string,
    code: string,
    newPassword: string
  ) => {
    const params = {
      ClientId: awsConfig.clientId,
      Username: email,
      ConfirmationCode: code,
      Password: newPassword,
    };

    try {
      await cognitoIdentityServiceProvider
        .confirmForgotPassword(params)
        .promise();
      setCurrentStep(StepsEnum.final);
    } catch (error) {
      console.error("Error confirming password reset:", error);
      setErrorMessage("Failed to reset password. Please try again.");
    }
  };

  return (
    <div className="wrapper">
      <main className="flex-wrapper main">
        <section className="">
          <div className="flex items-center justify-center container-custom">
            <div style={{
              width: 'calc(100vw - 32px)'
            }} className="relative accent-block my-4 px-10 py-6 max-w-[560px]">
              {currentStep !== StepsEnum.email &&
                currentStep !== StepsEnum.final && (
                  <BackStepButton
                    prevStep={() => {
                      if (currentStep === StepsEnum.code)
                        setCurrentStep(StepsEnum.email);
                      if (currentStep === StepsEnum.newPass)
                        setCurrentStep(StepsEnum.code);
                    }}
                  />
                )}
              <FormHeader
                title="Reset the password"
                description={headerDescription[currentStep] || ""}
              />
              {currentStep === StepsEnum.email && (
                <EmailStep
                  email={email}
                  setEmail={setEmail}
                  // nextStep={requestPasswordReset}
                  nextStep={() => requestPasswordReset(email)}
                  // nextStep={() => setCurrentStep(StepsEnum.code)}
                />
              )}
              {currentStep === StepsEnum.code && (
                <SecureCodeStep
                  email={email}
                  secureCode={code}
                  setSecureCode={setCode}
        
                  nextStep={() => setCurrentStep(StepsEnum.newPass)}
                  userPool={userPool}
                  
                />
              )}
              {currentStep === StepsEnum.newPass && (
                <PasswordStep
                  newPassword={newPassword}
                  setNewPassword={setNewPassword}
                  // confirmPasswordReset={confirmPasswordReset}
                  confirmPasswordReset={() =>
                    confirmPasswordReset(email, code, newPassword)
                  }
                  // nextStep={() => setCurrentStep(StepsEnum.final)}
                />
              )}
              {currentStep === StepsEnum.final && <FinalStep />}
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};