import { ReactNode } from 'react';

interface InputProps {
    htmlFor?: string;
    type?: 'text' | 'email' | 'password';
    name?: string;
    label?: string | ReactNode;
    id: string;
    placeholder?: string;
    value?: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    required?: boolean;
  }

export const Input = ({ htmlFor, type, name, id, placeholder, label, value, required, onChange }: InputProps) => {
  return (
    <div className="relative">
      <label
        className="text-[#2C2E30] text-[14px] font-medium inline-block mb-[6px]"
        htmlFor={htmlFor}
      >
        {label}
      </label>
      <input
        className="w-[100%] border-[#AAABAC] border rounded-xl py-[10px] px-[14px]"
        value={value}
        name={name}
        id={id}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        required={required}
      />
    </div>
  );
};
