import Shield from 'assets/images/shield.png';


export const SecuritySection = () => {
    return (
        <section className="lg:py-[48px] py-[36px] bg-[#F3F2FF] mb-[48px]">
          <div className="container-custom">
            <h2 className="font-semibold text-center leading-[120%] text-[#1D2E54] text-[24px] lg:text-[32px] lg:mb-4 mb-3">Data Security</h2>
            <div className="leading-[150%] text-center text-[14px] text-[#45484C] lg:text-[18px] lg:mb-4 mb-3">Built into Design</div>
            <div className="flex items-center justify-center flex-col gap-[20px] lg:flex-row lg:gap-[32px]">
            
                <img className="w-[50%] max-w-[225px]" src={Shield} alt="Brand." />
            
              <div className=" max-w-[318px]">
              <div className="font-semibold leading-[120%] text-[#1D2E54] text-[24px] mb-3 xl:mb-4 xl:text-[26px]">
              Data stays within
              your Private Network</div>
                <div className="leading-[150%] text-[#45484C] text-[14px] xl:text-[16px]">
                When you activate DataScoop inside your virtual private cloud, your data stays within the walls of your organization
                </div>
                </div>
              
            </div>
          </div>
        </section>
    )
}