export const DataPreviewTab = ({ data }: { data: object }) => {
  //@ts-ignore
  const head = data.fields.map(field => field.name)
  return (
    <div className="overflow-hidden border accent-block border-[#D5D6FD] max-w-[1310px] w-full">
      <div className="flex flex-col">
        <div className="lg:-mx-8 overflow-x-auto sm:-mx-6">
          <div className="min-w-full inline-block lg:px-8 pb-6 sm:px-6">
            <div className="overflow-hidden max-h-[604px] overflow-y-auto">
              <table className="min-w-full dark:text-white font-light text-[#35383B] text-left text-sm">
                <thead className="text-[#445082] border-[#E6E6E7] border-b dark:border-white/10 bg-[#F4F6F8] font-medium text-[12px]">
                  <tr>
                    {/* @ts-ignore */}
                    {head.map((item) => (
                      <th key={item} scope="col" className="p-6 py-4">
                        <div className="whitespace-nowrap flex items-center">
                          {item}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {/* @ts-ignore */}
                  {data.previewData.map((row) => (
                    <tr className="border-[#E6E6E7] border-b dark:border-white/10 text-[#445082]">
                      {Object.keys(row).map((key) => (
                        <td className="whitespace-nowrap p-6">{row[key]}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}