export interface AwsConfig {
    userPoolId: string;
    clientId: string;
  }
  
  // export const awsConfig = {
  //   userPoolId: 'us-east-1_JWt8vo2V6',
  //   clientId: '6lb5ifvrr732p5bv1knugj0r9b',
  //   region: 'us-east-1',
  //   accessKeyId: 'AKIA6GBMD6UMNW55ROVB',
  //   secretAccessKey: '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'
  //   };

    // export const awsConfig = {
    //   userPoolId: 'eu-north-1_NP11aFRlx',
    //   clientId: '1gsbhmb673empba6kgt9oglb94',
    //   region: 'eu-north-1',
    //   accessKeyId: 'AKIA2UC27HL337SETJX2',
    //   secretAccessKey: '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'
    //   };

      // export const awsConfig = {
      //   userPoolId: 'eu-north-1_WFBdM46Mv',
      //   clientId: '20g0r6irok0kng9h2qj2gjm7ru',
      //   region: 'eu-north-1',
      //   accessKeyId: 'AKIA2UC27HL337SETJX2',
      //   secretAccessKey: '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',
      //   };
  

      export const awsConfig = {
        userPoolId: 'us-west-1_qPnLD8LBH',
        clientId: '34rm0dco94hd8nn9q6oqnfosef',
        region: 'us-west-1',
        accessKeyId: 'AKIAQ7OHKGX7QF6E4SWL',
        secretAccessKey: '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'
        };