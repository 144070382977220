import AWS from 'assets/images/aws_marketplace.png';
import GCP from 'assets/images/gcp_marketplace.jpg';

export const MarketSection = () => {
    return (
      <section id="market" className="pb-[48px]">
        <div className="container-custom">
          <h2 className="font-semibold text-center leading-[120%] text-[#1D2E54] text-[24px] lg:text-[32px] lg:mb-4 mb-3">Also available in:</h2>
          {/* <!-- Cards for desktop --> */}
          <div className="flex items-center flex-col lg:flex-row justify-around gap-[40px]">
            <div className="relative rounded-[16px] shadow-lg px-[5px] py-[5px]">
            <img className="w-[100%] max-w-[380px]" src={GCP} alt="Mockup." />
            </div>
            <div className="relative rounded-[16px] shadow-lg px-[5px] py-[5px]">
            <img className="w-[100%] max-w-[380px]" src={AWS} alt="Mockup." />
            </div>
           
          </div>
        </div>
      </section>
    )
  }